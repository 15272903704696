import React, { memo } from "react";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import {
  Users,
  Rocket,
  ExternalLink,
  Trophy,
  BadgeCheck,
  AlertCircle,
  Mail,
  ArrowRight,
} from "lucide-react";

const partners = [
  {
    name: "EllasRipley",
    title: "Server Owner - The Project",
    description:
      "The project was created by EliasRipley as he wanted to build a multiplayer world featuring some of the best mods available, yet without introducing too much bloat. The idea was to create a server where building a beautiful community is the top priority. Notable mods include Create, Ars Nouveau, Alex's Mods, and Tectonic, along with a few extra dimensions for exploration.",
    imageUrl: "https://cdn.virtue-host.com/partners/theproject.webp",
    discordLink: "https://discord.com/invite/ptrYNsJRBp",
    tags: ["Minecraft", "Modded", "Whitelisted"],
  },
  {
    name: "Flibux Events",
    title: "Community - Flibux",
    description:
      "Flibux Events is a welcoming community that hosts Minecraft events. Owned by Tuxyxx, IQ Flinty, and MrBubbleFish, the group has a friendly staff team, including skilled developers, to assist members. They're looking to grow their community and encourage new faces to join the fun. ",
    imageUrl: "https://cdn.virtue-host.com/partners/Flibux.jpg",
    discordLink: "https://discord.gg/NNqXKvj8",
    tags: ["Minecraft", "Java", "Events"],
  },
  {
    name: "CorsaireMC",
    title: "Community - CorsaireMC",
    description: "pending update",
    imageUrl: "https://placehold.co/75",
    discordLink: "https://discord.gg/NNqXKvj8",
    tags: ["Minecraft", "Java"],
  },
];

// Component imports from both pages
const Section = memo(({ icon: Icon, title, children, index }) => {
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
    >
      <div className="rounded-xl bg-virtue-darker-accent/50 border border-virtue-border hover:border-virtue-primary-green/30 transition-all duration-300">
        <div className="p-6">
          <div className="flex items-center gap-4 mb-6">
            <div className="p-3 rounded-lg bg-virtue-primary-green/10">
              <Icon className="h-6 w-6 text-virtue-primary-green" />
            </div>
            <h2 className="text-xl font-bold text-virtue-text-white">{title}</h2>
          </div>
          <div className="text-virtue-text-gray leading-relaxed">{children}</div>
        </div>
      </div>
    </motion.div>
  );
});

const StatsCard = memo(({ icon: Icon, title, value }) => (
  <motion.div
    className="p-6 rounded-xl bg-virtue-darker-accent/50 border border-virtue-border"
    whileHover={{ scale: 1.02 }}
  >
    <div className="flex items-center gap-4">
      <div className="p-3 rounded-lg bg-virtue-primary-green/10">
        <Icon className="w-6 h-6 text-virtue-primary-green" />
      </div>
      <div>
        <p className="text-virtue-text-gray text-sm">{title}</p>
        <p className="text-2xl font-bold text-virtue-text-white">{value}</p>
      </div>
    </div>
  </motion.div>
));

const RequirementCard = memo(({ icon: Icon, title, description }) => (
  <motion.div
    className="p-6 rounded-xl bg-virtue-darker-accent/50 border border-virtue-border hover:border-virtue-primary-green/30 transition-all duration-300"
    whileHover={{ scale: 1.02 }}
  >
    <div className="p-3 rounded-lg bg-virtue-primary-green/10 w-fit mb-4">
      <Icon className="w-6 h-6 text-virtue-primary-green" />
    </div>
    <h3 className="text-lg font-bold text-virtue-text-white mb-2">{title}</h3>
    <p className="text-virtue-text-gray">{description}</p>
  </motion.div>
));

const DiscordIcon = memo(() => (
  <svg
    className="w-5 h-5"
    fill="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18.942 5.556a16.3 16.3 0 0 0-4.126-1.3 12.04 12.04 0 0 0-.529 1.1 15.175 15.175 0 0 0-4.573 0 11.586 11.586 0 0 0-.535-1.1 16.274 16.274 0 0 0-4.129 1.3 17.392 17.392 0 0 0-2.868 11.662 15.785 15.785 0 0 0 4.963 2.521c.41-.564.773-1.16 1.084-1.785a10.638 10.638 0 0 1-1.706-.83c.143-.106.283-.217.418-.331a11.664 11.664 0 0 0 10.118 0c.137.114.277.225.418.331-.544.328-1.116.606-1.71.832a12.58 12.58 0 0 0 1.084 1.785 16.46 16.46 0 0 0 5.064-2.595 17.286 17.286 0 0 0-2.973-11.59ZM8.678 14.813a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.918 1.918 0 0 1 1.8 2.047 1.929 1.929 0 0 1-1.8 2.045Zm6.644 0a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.919 1.919 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Z" />
  </svg>
));

const PartnerCard = memo(({ partner, index }) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      className="h-full"
    >
      <div className="relative h-full rounded-xl bg-virtue-darker-accent/50 border border-virtue-border overflow-hidden hover:border-virtue-primary-green/30 transition-all duration-300">
        <div className="p-6">
          <div className="flex items-start gap-4">
            <div className="relative shrink-0">
              <div className="w-24 h-24 rounded-xl overflow-hidden ring-2 ring-virtue-primary-green/20">
                <img
                  src={partner.imageUrl}
                  alt={partner.name}
                  className="w-full h-full object-cover"
                />
              </div>
              <a
                href={partner.discordLink}
                className="absolute -bottom-2 -right-2 p-2 bg-[#7289da] text-virtue-text-white rounded-lg shadow-lg hover:bg-virtue-secondary-green transition-colors duration-300"
                target="_blank"
                rel="noopener noreferrer"
              >
                <DiscordIcon />
              </a>
            </div>

            <div className="flex-1 min-w-0">
              <h2 className="text-xl font-bold text-virtue-text-white mb-1 hover:text-virtue-primary-green transition-colors duration-300">
                {partner.name}
              </h2>
              <p className="text-sm text-virtue-text-gray mb-3">{partner.title}</p>
              <div className="flex flex-wrap gap-2">
                {partner.tags.map((tag, idx) => (
                  <span
                    key={idx}
                    className="px-3 py-1 text-xs rounded-lg bg-virtue-card text-virtue-text-gray border border-virtue-border"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          </div>

          <p className="text-virtue-text-gray leading-relaxed mt-6">
            {partner.description}
          </p>
        </div>
      </div>
    </motion.div>
  );
});

const EmptyPartnerCard = memo(({ index }) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      className="h-full"
    >
      <div className="relative h-full rounded-xl bg-virtue-darker-accent/30 border border-virtue-border/50 border-dashed overflow-hidden group hover:border-virtue-primary-green/30 transition-all duration-300">
        <div className="absolute inset-0 bg-gradient-to-b from-virtue-primary-green/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
        <div className="relative h-full p-6 flex flex-col items-center justify-center">
          <div className="text-center">
            <div className="mb-4">
              <Users className="w-10 h-10 text-virtue-primary-green mx-auto" />
            </div>
            <h3 className="text-lg font-semibold text-virtue-text-white mb-2">
              Partner Slot Available
            </h3>
            <p className="text-virtue-text-gray mb-6">
              Join our growing network of partners
            </p>
            <motion.a
              href="https://discord.com/invite/UR5BD98Wn6"
              className="inline-flex items-center gap-2 px-4 py-2 bg-virtue-card text-virtue-text-white rounded-lg hover:bg-virtue-primary-green/20 transition-colors duration-300"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Apply Now
              <ExternalLink className="w-4 h-4" />
            </motion.a>
          </div>
        </div>
      </div>
    </motion.div>
  );
});

// Main Page Component
const PartnersPage = () => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <div className="min-h-screen ">
      <Helmet>
        <title>Partners | Virtue Host</title>
      </Helmet>

      <section className="relative  py-24 overflow-hidden">
        <div className="container mx-auto px-6 relative">
          <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 20 }}
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            className="text-center mb-16"
          >
            <div className="inline-flex items-center gap-2 px-4 py-2 rounded-xl bg-virtue-darker-accent border border-virtue-border mb-6">
              <Trophy className="w-5 h-5 text-virtue-primary-green" />
              <span className="text-base font-medium text-virtue-text-white">
                Partner Program
              </span>
            </div>

            <h1 className="text-4xl md:text-5xl font-bold mb-6">
              <span className="text-virtue-text-white">Join Our Network of </span>
              <span className="text-transparent bg-clip-text bg-virtue-primary-green">
                Partners
              </span>
            </h1>

            <p className="text-xl text-virtue-text-gray max-w-3xl mx-auto leading-relaxed">
              Partner with us to grow your community and receive exclusive benefits
            </p>
          </motion.div>
        </div>
      </section>

      <section className="container  max-w-screen-xl mx-auto px-6">
        <Section icon={AlertCircle} title="Important Notice" index={0}>
          <div className="p-6 rounded-xl bg-alertnotice/10 border border-alertnotice/30">
            <p className="text-virtue-text-gray">
              All users must comply with our Acceptable Use Policy to maintain their
              services. This policy may be updated periodically, and users are
              responsible for reviewing and complying with any changes.
            </p>
          </div>
        </Section>

        <div className="my-12">
            <h2 className="text-2xl font-bold text-virtue-text-white mb-6">
              Eligibility Requirements
            </h2>
            <div className="grid md:grid-cols-2 gap-6">
              <RequirementCard
                icon={Users}
                title="YouTube Content Creator"
                description="Channel with at least an average of 1,000 views per video"
              />
              <RequirementCard
                icon={Users}
                title="Twitch Streamer"
                description="Channel with at least 100-250 concurrent views"
              />
              <RequirementCard
                icon={Trophy}
                title="Server Owner"
                description="Server or network with 100 average active players"
              />
              <RequirementCard
                icon={Rocket}
                title="Content Developer"
                description="Modpack, resource pack, or plugin with at least 10,000+ downloads"
              />
            </div>
          </div>

        
          <Section icon={Rocket} title="Partnership Benefits" index={1} className="mb-12"> {/* Added mb-12 for spacing */}
            <ul className="space-y-3">
              {[
                "Sponsored servers for projects and events",
                "Custom affiliate link with discounts",
                "Priority support",
                "Perks for your community like discounts",
                "And much more"
              ].map((benefit, idx) => (
                <li key={idx} className="flex items-start gap-3">
                  <ArrowRight className="w-4 h-4 text-virtue-primary-green shrink-0 mt-1" />
                  <span>{benefit}</span>
                </li>
              ))}
            </ul>
          </Section>

          <div className="mt-16 mb-16"> {/* Added spacing */}
            <Section icon={Mail} title="Contact Our Partner Managers" index={2}>
              <div className="flex items-center gap-6">
                <motion.img
                  src="https://cdn.discordapp.com/avatars/999168396904120412/585a62c39845040211f411d14acbbf17?size=1024"
                  alt="bz"
                  className="w-16 h-16 rounded-xl ring-2 ring-virtue-primary-green/20"
                  whileHover={{ scale: 1.05 }}
                />
                <div>
                  <h4 className="text-xl font-bold text-virtue-text-white mb-1">BZ</h4>
                  <motion.a
                    href="mailto:bz@virtue-host.com"
                    className="inline-flex items-center gap-2 text-virtue-primary-green hover:text-virtue-secondary-green transition-colors duration-300 group"
                    whileHover={{ x: 4 }}
                  >
                    bz@virtue-host.com
                    <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
                  </motion.a>
                </div>
              </div>
            </Section>
          </div>

       
          <div className="mt-16 mb-32">
            <h2 className="text-2xl font-bold text-virtue-text-white mb-6">
              Our Current Partners
            </h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
              {partners.map((partner, index) => (
                <PartnerCard key={partner.name} partner={partner} index={index} />
              ))}

              {[...Array(Math.max(1, 6 - partners.length))].map((_, index) => (
                <EmptyPartnerCard
                  key={`empty-${index}`}
                  index={partners.length + index}
                />
              ))}
            </div>
          </div>
        </section>
      </div>
  );
};

export default memo(PartnersPage);
