// Loader.jsx
import React from "react";
import "./Loader.css";

const Loader = ({ isLoading }) => {
  return (
    <div className={`loader ${!isLoading ? 'hide' : ''}`}>
      <img src="https://cdn.virtue-host.com/company/logo.png" alt="Loading..." />
    </div>
  );
};

export default Loader;