import React, { memo } from "react";
import { useInView } from "react-intersection-observer";
import { ExternalLink, CheckCircle2 } from "lucide-react";

const technologies = [
  {
    name: "Pterodactyl",
    description: "Game Server Management",
    src: "https://cdn.virtue-host.com/company/pterodactyl_logo_transparent.webp",
    alt: "Pterodactyl",
    gradient: "from-virtue-primary-green to-virtue-accent-teal",
    link: "https://pterodactyl.io",
    features: ["Server automation", "Docker containers", "API access"],
  },
  {
    name: "WHMCS",
    description: "Advanced Billing & Automation",
    src: "https://cdn.virtue-host.com/company/whmcs-logo.webp",
    alt: "WHMCS",
    gradient: "from-mainblue to-virtue-accent-teal",
    link: "https://www.whmcs.com",
    features: ["Automated billing", "Client management", "Support system"],
  },
  {
    name: "Stripe",
    description: "Secure Payment Processing",
    src: "https://cdn.virtue-host.com/company/stripe.png",
    alt: "Stripe",
    gradient: "from-alertnotice to-virtue-primary-green",
    link: "https://stripe.com",
    features: ["Instant payments", "Fraud protection", "Global support"],
  },
  {
    name: "Cloudflare",
    description: "Enterprise Security & CDN",
    src: "https://cdn.virtue-host.com/company/Cloudflare_Logo.svg.webp",
    alt: "Cloudflare",
    gradient: "from-mainblue to-virtue-accent-teal",
    link: "https://www.cloudflare.com",
    features: ["Traffic Monitoring", "Global CDN", "SSL security"],
  },
];

const TechnologyCard = memo(
  ({ name, description, src, alt, gradient, link, features, index }) => {
    const { ref, inView } = useInView({
      threshold: 0.2,
      triggerOnce: true,
    });

    return (
      <div
        ref={ref}
        className={`relative group transition-all duration-500 transform
          ${
            inView
              ? "opacity-100 translate-y-0 scale-100"
              : "opacity-0 translate-y-5 scale-95"
          }`}
        style={{
          transitionDelay: `${index * 100}ms`,
        }}
      >
        <div className="absolute -inset-0.5 bg-gradient-to-r from-virtue-primary-green/20 to-virtue-accent-teal/20 rounded-xl blur opacity-0 group-hover:opacity-100 transition duration-300" />

        <div className="relative rounded-xl bg-virtue-darker-accent/50 border border-virtue-border hover:border-virtue-primary-green/30 transition-all duration-300 backdrop-blur-sm h-full">
          {/* Image container */}
          <div className="p-6 border-b border-virtue-border/50">
            <div
              className={`w-full h-28 rounded-lg bg-gradient-to-b ${gradient} p-0.5 transform transition-transform duration-300 group-hover:scale-105`}
            >
              <div className="w-full h-full bg-virtue-background rounded-lg flex items-center justify-center p-4 relative overflow-hidden">
                {/* Shine effect */}
                <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/5 to-transparent -translate-x-full group-hover:translate-x-full transition-transform duration-1000" />

                {/* Logo */}
                <img
                  src={src}
                  alt={alt}
                  className="h-16 w-auto object-contain relative z-10 transition-transform duration-200 hover:scale-105"
                  loading="lazy"
                />
              </div>
            </div>
          </div>

          {/* Content */}
          <div className="p-6 space-y-4">
            <div>
              <h3 className="text-xl font-semibold text-virtue-text-white group-hover:text-virtue-primary-green transition-colors duration-300">
                {name}
              </h3>
              <p className="text-virtue-text-muted mt-1">{description}</p>
            </div>

            {/* Features */}
            <div className="space-y-2">
              {features.map((feature, idx) => (
                <div
                  key={idx}
                  className="flex items-center gap-2 text-sm text-virtue-text-gray"
                >
                  <CheckCircle2 className="w-4 h-4 text-virtue-primary-green" />
                  <span>{feature}</span>
                </div>
              ))}
            </div>

            {/* Learn more link */}
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center gap-2 text-sm text-virtue-primary-green hover:text-virtue-secondary-green transition-all duration-300 mt-4 group/link hover:translate-x-1"
            >
              Learn more
              <ExternalLink
                size={14}
                className="opacity-50 group-hover/link:opacity-100 transition-opacity"
              />
            </a>
          </div>
        </div>
      </div>
    );
  }
);

TechnologyCard.displayName = "TechnologyCard";

const Technologies = () => {
  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  return (
    <section
      ref={ref}
      className="bg-virtue-background py-24 relative overflow-hidden"
    >
      <div className="container mx-auto px-6 max-w-screen-xl relative">
        {/* Header */}
        <div
          className={`text-center mb-16 transition-all duration-500 transform
            ${
              inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-5"
            }`}
        >
          <h2 className="text-4xl md:text-5xl font-bold mb-6">
            <span className="text-virtue-text-white">Powered by </span>
            <span className="text-transparent bg-clip-text bg-virtue-primary-green">
              Industry Leaders
            </span>
          </h2>

          <p className="text-xl text-virtue-text-muted max-w-2xl mx-auto leading-relaxed">
            Our infrastructure leverages enterprise-grade technologies to
            deliver secure, reliable, and scalable game hosting solutions.
          </p>
        </div>

        {/* Technology grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {technologies.map((tech, index) => (
            <TechnologyCard key={tech.name} {...tech} index={index} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default memo(Technologies);
