import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { FaHome } from "react-icons/fa";

const NotFound = () => {
  return (
    <div className="min-h-screen bg-gray-900 flex flex-col items-center justify-center text-white px-4">
      <Helmet>
        <title>404 Not Found | Virtue Host</title>
        <meta name="description" content="Page not found - Virtue Host" />
      </Helmet>
      <div className="text-center max-w-2xl">
        <img
          src="https://cdn.virtue-host.com/company/logo.png"
          alt="Virtue Host Logo"
          className="w-22 h-auto mx-auto mb-8"
        />
        <h1 className="text-8xl font-extrabold text-maingreen mb-4">404</h1>
        <div className="relative">
          <p className="text-5xl font-bold mb-6 relative">Page Not Found</p>
        </div>
        <p className="text-2xl font-semibold text-gray-300 mb-8">
          Oops! It seems like you've ventured into uncharted digital territory. 
          The page you're looking for might have been moved, deleted, or never existed in the first place.
        </p>
        <div className="flex flex-col sm:flex-row gap-4 justify-center">
          <Link
            to="/"
            className="inline-flex items-center justify-center px-6 py-3 bg-maingreen hover:bg-green-600 text-white font-bold rounded-xl transition duration-300 ease-in-out"
          >
            <FaHome className="mr-2" />
            Go to Home
          </Link>
        </div>
      </div>
      <div className="mt-16 text-gray-400">
        <p>If you believe this is an error, please <a href="https://billing.virtue-host.com/submitticket.php" className="text-maingreen hover:underline">contact our support team</a>.</p>
      </div>
    </div>
  );
};

export default NotFound;