import React from "react";
import { Helmet } from "react-helmet";
import HeroSection from "../components/home/HeroSection";
import FeaturesSection from "../components/home/FeaturesSection";
import GlobalLocations from "../components/home/GlobalLocations";
import Technologies from "../components/home/Technologies";
import Comparison from "../components/home/Comparison";
import Reviews from "../components/home/Reviews";
import Faq from "../components/Faq";
import Panel from "../components/Panel";

const HomePage = () => {
  return (
    <div className="relative">
      {/* SEO Tags */}
      <Helmet>
        <title>Game Server Hosting | Virtue Host</title>
        <meta
          name="description"
          content="Explore tailored hosting solutions at Virtue Host for your Minecraft, Game Servers or Website hosting needs. Experience Virtue. Elevate Hosting."
        />
        <meta
          name="keywords"
          content="Game Servers, Minecraft Hosting, Bot Hosting"
        />
        {/* Open Graph meta tags */}
        <meta
          property="og:image"
          content="https://virtue-host.com/assets/images/company/logo.png"
        />
        <meta
          property="og:image:secure_url"
          content="https://virtue-host.com/assets/images/company/logo.png"
        />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="96" />
        <meta property="og:image:height" content="96" />
        <meta property="og:image:alt" content="Virtue Host Logo" />

        {/* Twitter meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:image:src"
          content="https://virtue-host.com/assets/images/company/logo.png"
        />
        <meta
          name="twitter:title"
          content="Game Server Hosting - Virtue Host"
        />
        <meta
          name="twitter:description"
          content="Explore tailored hosting solutions at Virtue Host for your Minecraft, Game Servers or Website hosting needs. Experience Virtue. Elevate Hosting."
        />
        <meta
          name="twitter:image"
          content="https://virtue-host.com/assets/images/company/logo.png"
        />

        {/* Link tags */}
        <link rel="canonical" href="https://virtue-host.com/" />
        <link
          rel="apple-touch-icon"
          href="https://virtue-host.com/assets/images/company/logo.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="https://virtue-host.com/assets/images/company/logo.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="https://virtue-host.com/assets/images/company/logo.png"
        />
        <link rel="manifest" href="" />
        <link
          rel="mask-icon"
          href="https://virtue-host.com/assets/images/company/logo.png"
          color="#55FF55"
        />
        <link
          rel="shortcut icon"
          href="https://virtue-host.com/assets/images/company/logo.png"
        />

        {/* Microsoft-specific meta tags */}
        <meta name="msapplication-TileColor" content="#55FF55" />
        <meta name="msapplication-config" content="" />
        <meta name="theme-color" content="#55FF55" />
      </Helmet>

      {/* Page sections */}
      <HeroSection />
      <FeaturesSection />
      <Panel />
      <GlobalLocations />
      <Technologies />
      <Comparison />
      <Reviews />
      <Faq />
    </div>
  );
};

export default HomePage;
