import React, { memo } from "react";
import { useInView } from "react-intersection-observer";
import { Star, ExternalLink, Quote, Shield } from "lucide-react";

const reviewsData = [
  {
    id: 1,
    name: "D_rip",
    role: "Game Server Owner",
    text: "Switched from Shockbyte and I gotta say it is well worth it for the price and specs. Also having no hidden fees satisfies me.",
    rating: 5,
    platform: "Trustpilot",
    verified: true,
  },
  {
    id: 2,
    name: "Luca K.",
    role: "Game Server Owner",
    text: "ABSOLUTELY AMAZING!!! I rate this ♾️/10. The owner is so nice and active. I have received numerous discounts. The owner even helps me with stuff that doesn't benefit him.",
    rating: 5,
    platform: "Trustpilot",
    verified: true,
  },
  {
    id: 3,
    name: "EllasRipley",
    role: "Partnered Community",
    text: "I've owned many servers over the years and hosted with various companies. Virtue Host stands out for its reliable performance at a great price.",
    rating: 5,
    platform: "Trustpilot",
    verified: true,
  },
];

const RatingStars = memo(({ rating }) => (
  <div className="flex items-center gap-1.5">
    {[...Array(5)].map((_, i) => (
      <div
        key={i}
        className={`transform transition-all duration-200 ease-out
          ${
            i === 0
              ? "delay-[0ms]"
              : i === 1
              ? "delay-100"
              : i === 2
              ? "delay-200"
              : i === 3
              ? "delay-300"
              : "delay-400"
          }
          scale-0 opacity-0 animate-in-stars`}
      >
        <Star
          className={`w-4 h-4 ${
            i < rating
              ? "fill-alertnotice text-alertnotice"
              : "text-virtue-text-muted"
          }`}
        />
      </div>
    ))}
  </div>
));

RatingStars.displayName = "RatingStars";

const ReviewCard = memo(({ review, index }) => {
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  return (
    <div
      ref={ref}
      className={`relative group transform transition-all duration-500 ease-out
        ${inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-5"}`}
      style={{ transitionDelay: `${index * 150}ms` }}
    >
      {/* Glow effect */}
      <div className="absolute -inset-0.5 bg-gradient-to-r from-virtue-primary-green/20 to-virtue-accent-teal/20 rounded-xl blur opacity-0 group-hover:opacity-100 transition duration-300" />

      {/* Card content */}
      <div
        className="relative h-full bg-virtue-darker-accent/50 border border-virtue-border rounded-xl p-6 backdrop-blur-sm transition-all duration-300 
                    group-hover:border-virtue-primary-green/30"
      >
        {/* Header */}
        <div className="flex items-start justify-between mb-6">
          <div className="flex items-center gap-4">
            <div className="relative">
              <div
                className="w-12 h-12 bg-virtue-primary-green/75 rounded-lg flex items-center justify-center 
                            text-virtue-text-white text-xl font-bold shadow-lg"
              >
                {review.name.charAt(0)}
              </div>
              <div className="absolute -right-1 -bottom-1 bg-alertnotice rounded-full p-1">
                <Shield className="w-3 h-3 text-virtue-text-white" />
              </div>
            </div>
            <div>
              <h3 className="text-virtue-text-white font-medium group-hover:text-virtue-primary-green transition-colors duration-300">
                {review.name}
              </h3>
              <div className="flex items-center gap-2 mt-1">
                <span className="text-sm text-virtue-text-muted">
                  {review.role}
                </span>
                {review.verified && (
                  <span className="inline-flex items-center gap-1 text-xs px-2 py-0.5 rounded-full bg-virtue-primary-green/10 text-virtue-primary-green border border-virtue-primary-green/20">
                    Verified
                  </span>
                )}
              </div>
            </div>
          </div>
          <span className="text-sm text-virtue-text-muted">
            {review.platform}
          </span>
        </div>

        {/* Review content */}
        <div className="relative mb-6 pl-6">
          <Quote className="absolute top-0 left-0 w-4 h-4 text-virtue-primary-green/20" />
          <p className="text-virtue-text-gray leading-relaxed">{review.text}</p>
        </div>

        {/* Footer */}
        <div className="flex items-center justify-between">
          <RatingStars rating={review.rating} />
        </div>
      </div>
    </div>
  );
});

ReviewCard.displayName = "ReviewCard";

const Reviews = () => {
  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  return (
    <section
      className="bg-virtue-background py-24 relative overflow-hidden"
      ref={ref}
    >
      <div className="container mx-auto px-6 max-w-screen-xl relative">
        {/* Section header */}
        <div
          className={`text-center mb-16 transform transition-all duration-700 ease-out
          ${inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-5"}`}
        >
          <div className="inline-flex items-center gap-2 px-4 py-2 rounded-xl bg-virtue-darker-accent border border-virtue-border mb-6">
            <Star className="w-5 h-5 text-virtue-primary-green" />
            <span className="text-base font-medium text-virtue-text-white">
              Customer Reviews
            </span>
          </div>

          <h2 className="text-4xl md:text-5xl font-bold mb-6">
            <span className="text-virtue-text-white">Trusted by </span>
            <span className="text-transparent bg-clip-text bg-virtue-primary-green">
              Gaming Communities
            </span>
          </h2>

          <p className="text-xl text-virtue-text-muted max-w-2xl mx-auto leading-relaxed">
            See what our customers say about their experience with our premium
            game hosting services
          </p>
        </div>

        {/* Reviews grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {reviewsData.map((review, index) => (
            <ReviewCard key={review.id} review={review} index={index} />
          ))}
        </div>

        {/* CTA button */}
        <div
          className={`mt-12 text-center transform transition-all duration-500 ease-out
          ${inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-5"}`}
          style={{ transitionDelay: "600ms" }}
        >
          <a
            href="https://www.trustpilot.com/review/virtue-host.com"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center gap-2 px-6 py-3 bg-virtue-primary-green/75 hover:bg-virtue-secondary-green 
                     rounded-lg text-virtue-text-white font-medium transition-all duration-300 group
                     hover:scale-[1.02] active:scale-[0.98]"
          >
            Read More Reviews on Trustpilot
            <ExternalLink className="w-4 h-4 transition-transform duration-300 group-hover:translate-x-0.5" />
          </a>
        </div>
      </div>
    </section>
  );
};

// Add the custom animation for stars
const style = document.createElement("style");
style.textContent = `
  .animate-in-stars {
    animation: scaleIn 0.2s ease-out forwards;
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(0);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
`;
document.head.appendChild(style);

export default memo(Reviews);
