import React, { useState, memo, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { 
  Plus,
  X,
  HelpCircle,
  Server,
  Bot,
  Search,
  Gamepad,
  Settings,
  Globe,
  CreditCard,
  Gamepad2
} from "lucide-react";

const faqData = {
  general: [
    {
      question: "What is Virtue Host?",
      answer: "Virtue Host offers comprehensive online hosting solutions, including Game Servers, Website Hosting, Discord Bot Hosting, and more.",
      icon: <Server className="w-5 h-5" />
    },
    {
      question: "What regions does Virtue Host service?",
      answer: "Our services support all regions; however, for optimal performance, it's best to choose game server hardware closer to your region.",
      icon: <Globe className="w-5 h-5" />
    },
    {
      question: "How does support work?",
      answer: "We offer top-tier support primarily through our client area, complemented by support via our Discord Ticket System.",
      icon: <HelpCircle className="w-5 h-5" />
    },
    {
      question: "Can I get a refund?",
      answer: "We offer our customers a 48-hour refund policy.",
      icon: <CreditCard className="w-5 h-5" />
    },
    {
      question: "A game I want to host is not on the Virtue Host site?",
      answer: "If a game you wish to host is not listed on the Virtue Host site, we do not offer services for that particular game.",
      icon: <Gamepad className="w-5 h-5" />
    }
  ],
  minecraft: [
    {
      question: "Do you offer server locations in different regions?",
      answer: "We currently provide server locations in North America and Europe.",
      icon: <Globe className="w-5 h-5" />
    },
    {
      question: "Can I switch between different Minecraft server versions?",
      answer: "Yes, it's accessible through our game panel.",
      icon: <Settings className="w-5 h-5" />
    },
    {
      question: "Can I upgrade or downgrade my server plan?",
      answer: "Yes, it's accessible through our client area.",
      icon: <Server className="w-5 h-5" />
    },
    {
      question: "What version of Minecraft do you support?",
      answer: "All versions!",
      icon: <Gamepad className="w-5 h-5" />
    }
  ],
  "bot-hosting": [
    {
      question: "What locations does Virtue Host offer for Bot Hosting?",
      answer: "Bot hosting hardware is located in the Europe region. However, it's important to note that bot hosting can typically be done from anywhere and accessed globally.",
      icon: <Globe className="w-5 h-5" />
    },
    {
      question: "Can I host multiple Discord bots on the same server?",
      answer: "Yes! For all bot languages except Java.",
      icon: <Bot className="w-5 h-5" />
    },
    {
      question: "How do I monitor and manage my hosted Discord bot?",
      answer: "Within the Virtue Host Control Panel and/or FTP.",
      icon: <Settings className="w-5 h-5" />
    }
  ]
};

const containerVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: {
      duration: 0.5,
      staggerChildren: 0.1
    }
  }
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: { duration: 0.3 }
  }
};

const FAQItem = memo(({ question, answer, icon, isOpen, onClick, index }) => {
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
    rootMargin: "50px"
  });

  return (
    <div
      ref={ref}
      className={`mb-4 transform transition-all duration-500 ease-out
        ${inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-5'}`}
      style={{ transitionDelay: `${index * 100}ms` }}
    >
      <button
        onClick={onClick}
        className="w-full p-4 rounded-xl bg-slate-900/50 border border-slate-800 hover:border-green-500/30 
                   transition-all duration-300 text-left flex items-center justify-between group
                   hover:scale-[1.01] active:scale-[0.99]"
        aria-expanded={isOpen}
      >
        <div className="flex items-center gap-3 pr-8">
          <div className={`p-2 rounded-lg ${isOpen ? 'bg-green-500/10 text-green-400' : 'bg-slate-800 text-gray-400'} 
                        transition-colors duration-300 group-hover:text-green-400`}>
            {icon}
          </div>
          <span className="text-gray-200 font-medium group-hover:text-white transition-colors duration-300">
            {question}
          </span>
        </div>
        <div className={`p-2 rounded-lg ${isOpen ? 'bg-green-500/10' : 'bg-slate-800'} transition-colors duration-300`}>
          <div className={`transform transition-transform duration-200 ${isOpen ? 'rotate-45' : 'rotate-0'}`}>
            {isOpen ? (
              <X className="w-4 h-4 text-green-400" />
            ) : (
              <Plus className="w-4 h-4 text-gray-400 group-hover:text-green-400 transition-colors duration-300" />
            )}
          </div>
        </div>
      </button>

      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ 
              height: "auto", 
              opacity: 1,
              transition: {
                height: { duration: 0.2 },
                opacity: { duration: 0.3, delay: 0.1 }
              }
            }}
            exit={{ 
              height: 0, 
              opacity: 0,
              transition: {
                height: { duration: 0.2 },
                opacity: { duration: 0.1 }
              }
            }}
            className="overflow-hidden"
          >
            <div className="p-6 mt-3 rounded-xl bg-slate-800/30 border border-slate-800 transform transition-all duration-300">
              <p className="text-gray-300 leading-relaxed">{answer}</p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
});

FAQItem.displayName = 'FAQItem';

const CategoryButton = memo(({ category, isActive, onClick, icon }) => (
  <button
    onClick={onClick}
    className={`w-full flex items-center gap-3 p-3 rounded-lg transition-all duration-300 
                hover:translate-x-1 active:scale-98
                ${isActive 
                  ? "bg-slate-800 border-l-4 border-green-500 shadow-lg" 
                  : "hover:bg-slate-800/50"
                }`}
    aria-pressed={isActive}
  >
    <div className={`p-2 rounded-lg ${isActive ? 'bg-green-500/10 text-green-400' : 'bg-slate-800/50 text-gray-400'}`}>
      {icon}
    </div>
    <span className={`font-medium ${isActive ? 'text-white' : 'text-gray-400'}`}>
      {category.name}
    </span>
  </button>
));

CategoryButton.displayName = 'CategoryButton';

const FAQ = () => {
  const [activeCategory, setActiveCategory] = useState("general");
  const [openQuestion, setOpenQuestion] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  
  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true
  });

  const categories = [
    { id: "general", name: "General Questions", icon: <HelpCircle className="w-5 h-5" /> },
    { id: "minecraft", name: "Minecraft Hosting", icon: <Gamepad2 className="w-5 h-5" /> },
    { id: "bot-hosting", name: "Bot Hosting", icon: <Bot className="w-5 h-5" /> }
  ];

  const handleCategoryChange = useCallback((categoryId) => {
    setActiveCategory(categoryId);
    setOpenQuestion(null);
    setSearchQuery("");
  }, []);

  const handleQuestionToggle = useCallback((index) => {
    setOpenQuestion(prev => prev === index ? null : index);
  }, []);

  const filteredQuestions = faqData[activeCategory].filter(faq => 
    faq.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
    faq.answer.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <section ref={ref} className="bg-slate-950 py-24 relative overflow-hidden">
      {/* Background effects */}
      <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-green-500/5 via-slate-950 to-slate-950" />
      
      <div className="container mx-auto px-6 max-w-screen-xl relative">
        {/* Header */}
        <div className={`text-center mb-16 transform transition-all duration-700 ease-out
          ${inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-5'}`}>
          <div className="inline-flex items-center gap-2 px-4 py-2 rounded-xl bg-slate-900 border border-slate-800 mb-6">
            <HelpCircle className="w-5 h-5 text-green-400" />
            <span className="text-base font-medium text-white">
              Quick Answers
            </span>
          </div>

          <h2 className="text-4xl md:text-5xl font-bold mb-6">
            <span className="text-white">Frequently Asked </span>
            <span className="text-transparent bg-clip-text bg-green-400">
              Questions
            </span>
          </h2>
          
          <p className="text-xl text-gray-400 max-w-3xl mx-auto leading-relaxed">
            Find answers to common questions about our game hosting services and features
          </p>

          {/* Search box */}
          <div className="max-w-md mx-auto mt-8">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
              <input
                type="text"
                placeholder="Search questions..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full pl-10 pr-4 py-3 bg-slate-900 border border-slate-800 rounded-lg text-gray-200 placeholder-gray-400
                         focus:outline-none focus:border-green-500/30 transition-colors duration-300"
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row gap-8">
          {/* Categories sidebar */}
          <div className={`lg:w-1/4 transform transition-all duration-500 ease-out
            ${inView ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-5'}`}>
            <div className="sticky top-24 space-y-4">
              <h3 className="text-lg font-medium text-white mb-4">
                Categories
              </h3>
              <div className="space-y-2">
                {categories.map((category) => (
                  <CategoryButton
                    key={category.id}
                    category={category}
                    icon={category.icon}
                    isActive={activeCategory === category.id}
                    onClick={() => handleCategoryChange(category.id)}
                  />
                ))}
              </div>
            </div>
          </div>

          {/* FAQ items */}
          <div className={`lg:w-3/4 transform transition-all duration-500 ease-out
            ${inView ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-5'}`}>
            <div className="space-y-4">
              {filteredQuestions.map((faq, index) => (
                <FAQItem
                  key={index}
                  question={faq.question}
                  answer={faq.answer}
                  icon={faq.icon}
                  isOpen={openQuestion === index}
                  onClick={() => handleQuestionToggle(index)}
                  index={index}
                />
              ))}
              {filteredQuestions.length === 0 && (
                <div className="text-center py-12 opacity-0 animate-fade-in">
                  <Search className="w-12 h-12 text-gray-400 mx-auto mb-4" />
                  <p className="text-gray-400">No matching questions found</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

// Add the custom animation for fade in
const style = document.createElement('style');
style.textContent = `
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .animate-fade-in {
    animation: fadeIn 0.3s ease-out forwards;
  }
`;
document.head.appendChild(style);

export default memo(FAQ);
