import React, { memo } from "react";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import {
  Mail,
  AlertCircle,
  Briefcase,
  Clock,
  Laptop,
  Target,
  Globe,
  GraduationCap,
  ArrowRight,
} from "lucide-react";

const benefits = [
  {
    icon: Clock,
    title: "Flexible Schedule",
    description:
      "Work when it suits you best with our flexible contracting hours",
  },
  {
    icon: Globe,
    title: "Remote Work",
    description:
      "Work from anywhere - all you need is a reliable internet connection",
  },
  {
    icon: Laptop,
    title: "Technical Growth",
    description:
      "Hands-on experience with game servers and hosting infrastructure",
  },
  {
    icon: GraduationCap,
    title: "Learning Opportunities",
    description:
      "Gain valuable real-time experience in the game hosting industry",
  },
];

const Section = memo(
  ({ icon: Icon, title, children, className = "", index }) => {
    const { ref, inView } = useInView({
      threshold: 0.2,
      triggerOnce: true,
    });

    return (
      <motion.div
        ref={ref}
        initial={{ opacity: 0, y: 20 }}
        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
        transition={{ duration: 0.5, delay: index * 0.1 }}
        className={className}
      >
        <div className="h-full rounded-xl bg-virtue-darker-accent/50 border border-virtue-border hover:border-virtue-primary-green/30 transition-all duration-300">
          <div className="p-6">
            <div className="flex items-center gap-4 mb-6">
              <div className="p-3 rounded-lg bg-virtue-primary-green/10">
                <Icon className="h-6 w-6 text-virtue-primary-green" />
              </div>
              <h2 className="text-xl font-bold text-virtue-text-white">
                {title}
              </h2>
            </div>
            <div className="text-virtue-text-gray leading-relaxed">
              {children}
            </div>
          </div>
        </div>
      </motion.div>
    );
  }
);

Section.displayName = "Section";

const BenefitCard = memo(({ icon: Icon, title, description, index }) => {
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
    >
      <motion.div
        className="h-full p-6 rounded-xl bg-virtue-darker-accent/50 border border-virtue-border hover:border-virtue-primary-green/30 transition-all duration-300"
        whileHover={{ scale: 1.02 }}
      >
        <div className="p-3 rounded-lg bg-virtue-primary-green/10 w-fit mb-4">
          <Icon className="w-6 h-6 text-virtue-primary-green" />
        </div>
        <h3 className="text-lg font-bold text-virtue-text-white mb-3">
          {title}
        </h3>
        <p className="text-virtue-text-gray leading-relaxed">{description}</p>
      </motion.div>
    </motion.div>
  );
});

BenefitCard.displayName = "BenefitCard";

const StatsCard = memo(({ icon: Icon, title, value }) => (
  <motion.div
    className="p-6 rounded-xl bg-virtue-darker-accent/50 border border-virtue-border"
    whileHover={{ scale: 1.02 }}
  >
    <div className="flex items-center gap-4">
      <div className="p-3 rounded-lg bg-virtue-primary-green/10">
        <Icon className="w-6 h-6 text-virtue-primary-green" />
      </div>
      <div>
        <p className="text-virtue-text-gray text-sm">{title}</p>
        <p className="text-2xl font-bold text-virtue-text-white">{value}</p>
      </div>
    </div>
  </motion.div>
));

StatsCard.displayName = "StatsCard";

const JobsPage = () => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <div className="min-h-screen bg-virtue-background">
      <Helmet>
        <title>Job Opportunities | Virtue Host</title>
        <meta
          name="description"
          content="Join Virtue Host's team of passionate contractors. Flexible remote work opportunities in game server hosting and management."
        />
      </Helmet>

      {/* Hero Section */}
      <section className="relative py-24 overflow-hidden">
        <div className="container mx-auto px-6 relative">
          <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 20 }}
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            className="text-center mb-16"
          >
            <div className="inline-flex items-center gap-2 px-4 py-2 rounded-xl bg-virtue-darker-accent border border-virtue-border mb-6">
              <Briefcase className="w-5 h-5 text-virtue-primary-green" />
              <span className="text-base font-medium text-virtue-text-white">
                Join Our Team
              </span>
            </div>

            <h1 className="text-4xl md:text-5xl font-bold mb-6">
              <span className="text-virtue-text-white">Work With Our </span>
              <span className="text-transparent bg-clip-text bg-virtue-primary-green">
                Growing Company
              </span>
            </h1>

            <p className="text-xl text-virtue-text-gray max-w-3xl mx-auto leading-relaxed">
              Join our network of contractors helping gamers get their servers
              up and running smoothly
            </p>
          </motion.div>
        </div>
      </section>

      {/* Main Content */}
      <section className="container mx-auto max-w-screen-xl px-6 pb-24">
        {/* Why Work With Us */}
        <Section
          icon={Target}
          title="Why Work With Us?"
          index={0}
          className="mb-12"
        >
          <p className="mb-6">
            We're a small but growing game server hosting company looking for
            passionate contractors who understand gaming communities and server
            management. Whether you're an experienced system admin or a gaming
            enthusiast with technical skills, we'd love to work with you.
          </p>
          <ul className="space-y-4">
            {[
              "Choose your working hours and manage your own schedule",
              "Competitive hourly rates based on experience and expertise",
              "Opportunity to grow with our company as we expand",
            ].map((item, idx) => (
              <li key={idx} className="flex items-start gap-3">
                <ArrowRight className="w-5 h-5 text-virtue-primary-green shrink-0 mt-1" />
                <span>{item}</span>
              </li>
            ))}
          </ul>
        </Section>

        {/* Benefits Grid */}
        <div className="mb-12">
          <h2 className="text-2xl font-bold text-virtue-text-white mb-6">
            Contractor Benefits
          </h2>
          <div className="grid md:grid-cols-2 gap-6">
            {benefits.map((benefit, index) => (
              <BenefitCard key={index} {...benefit} index={index} />
            ))}
          </div>
        </div>

        {/* Current Opportunities */}
        <Section
          icon={AlertCircle}
          title="Current Opportunities"
          index={1}
          className="mb-12"
        >
          <div className="text-center py-8">
            <h3 className="text-xl font-bold text-virtue-text-white mb-4">
              No Positions Currently Available
            </h3>
            <p className="text-virtue-text-gray mb-8 max-w-2xl mx-auto">
              While we don't have any open contractor positions at the moment,
              we're always looking to connect with talented individuals. Sign up
              for notifications to learn about new opportunities as we grow.
            </p>
            <motion.a
              href="https://billing.virtue-host.com/register.php"
              className="inline-flex items-center gap-2 px-6 py-3 bg-virtue-primary-green/75 text-virtue-text-white font-medium rounded-xl hover:bg-virtue-secondary-green transition-colors duration-300 shadow-lg shadow-virtue-primary-green/25 group"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <Mail className="w-5 h-5" />
              Sign Up for Notifications
              <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
            </motion.a>
          </div>
        </Section>
      </section>
    </div>
  );
};

export default memo(JobsPage);
