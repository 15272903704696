import React from "react";
import { motion } from "framer-motion";
import {
  ChevronRight,
  PickaxeIcon,
  Zap,
  Shield,
  Gamepad2,
  Clock,
  CpuIcon,
} from "lucide-react";

const HeroSection = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15,
        delayChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, ease: "easeOut" },
    },
  };

  return (
    <section className="relative min-h-screen bg-virtue-background overflow-hidden">
      {/* Main content */}
      <motion.div
        className="relative z-10 px-6 py-24 mx-auto max-w-screen-xl"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <div className="flex flex-col items-center sm:mt-24 md:mt-24 text-center">
          {/* Game server status */}
          <div className="group flex items-center gap-3 px-6 py-3 mb-12 bg-virtue-darker-accent/80 border border-virtue-border rounded-full transition-all duration-300 hover:border-virtue-primary-green/30 backdrop-blur-md hover:bg-virtue-darker-accent/90 hover:scale-[1.02] active:scale-[0.98]">
            <div className="relative">
              <div className="w-2.5 h-2.5 bg-virtue-primary-green rounded-full animate-pulse" />
              <div className="absolute inset-0 bg-virtue-primary-green rounded-full animate-ping opacity-75" />
            </div>

            <a
              href="https://status.virtue-host.com"
              className="flex items-center gap-2 transform group-hover:translate-x-1 transition-transform duration-300"
            >
              <span className="text-virtue-text-gray font-medium">
                Network Status:{" "}
                <span className="text-virtue-primary-green font-semibold">
                  All Systems Operational
                </span>
              </span>
              <ChevronRight className="w-4 h-4 text-virtue-primary-green opacity-0 group-hover:opacity-100 transition-all duration-300" />
            </a>
          </div>

          {/* Main heading */}
          <motion.div variants={itemVariants} className="space-y-8 mb-12">
            <div className="relative">
              <h1 className="text-5xl md:text-7xl font-bold text-virtue-text-white leading-tight">
                Conquer Worlds with
                <motion.span
                  className="block text-transparent bg-clip-text bg-virtue-primary-green"
                  animate={{
                    backgroundPosition: ["0% center", "200% center"],
                  }}
                  transition={{
                    duration: 10,
                    repeat: Infinity,
                    ease: "linear",
                  }}
                  style={{
                    backgroundSize: "200% auto",
                  }}
                >
                  Ryzen Game Servers
                </motion.span>
              </h1>
              {/* Glow effect */}
              <div className="absolute -inset-x-20 -inset-y-10 bg-gradient-to-r from-virtue-primary-green/0 via-virtue-primary-green/5 to-virtue-primary-green/0 blur-3xl opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
            </div>
            <p className="text-xl md:text-2xl text-virtue-text-muted max-w-2xl mx-auto leading-relaxed">
              Launch your game server in seconds with enterprise-grade hardware.
              Experience ultra-low latency and 24/7 support for unmatched
              performance.
            </p>
          </motion.div>

          {/* Feature badges */}
          <motion.div
            variants={itemVariants}
            className="flex flex-wrap justify-center gap-4 mb-12"
          >
            {[
              { icon: <CpuIcon className="w-5 h-5" />, text: "Ryzen CPUs" },
              { icon: <Shield className="w-5 h-5" />, text: "DDoS Protection" },
              { icon: <Clock className="w-5 h-5" />, text: "Instant Setup" },
              { icon: <Zap className="w-5 h-5" />, text: "Superior Uptime" },
            ].map((feature, index) => (
              <div
                key={index}
                className="flex items-center gap-2 px-4 py-2 bg-virtue-darker-accent/50 rounded-lg backdrop-blur-sm border border-virtue-border hover:border-virtue-primary-green/20 transition-all duration-300"
              >
                <span className="text-virtue-primary-green">
                  {feature.icon}
                </span>
                <span className="text-sm font-medium text-virtue-text-gray">
                  {feature.text}
                </span>
              </div>
            ))}
          </motion.div>

          {/* CTA buttons */}
          <div className="flex flex-col sm:flex-row gap-4 w-full justify-center">
            <a
              href="/minecraft"
              className="inline-flex items-center justify-center px-8 py-4 text-lg font-semibold text-virtue-text-white bg-virtue-primary-green/75 rounded-xl hover:shadow-xl hover:shadow-virtue-primary-green/20 transition-all duration-300 group relative overflow-hidden hover:scale-[1.02] active:scale-[0.98]"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-virtue-secondary-green/0 via-virtue-secondary-green/30 to-virtue-secondary-green/0 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              <div className="animate-spin-slow mr-2">
                <PickaxeIcon className="w-5 h-5" />
              </div>
              Deploy Minecraft Server
              <ChevronRight className="w-5 h-5 ml-1 transform group-hover:translate-x-1 transition-transform duration-300" />
            </a>

            <a
              href="/games"
              className="inline-flex items-center justify-center px-8 py-4 text-lg font-semibold text-virtue-text-gray bg-virtue-card/80 rounded-xl hover:bg-virtue-card border border-virtue-border hover:border-virtue-primary-green/30 transition-all duration-300 backdrop-blur-sm group hover:scale-[1.02] active:scale-[0.98]"
            >
              <Gamepad2 className="w-5 h-5 mr-2 text-virtue-primary-green transform group-hover:rotate-12 transition-transform duration-300" />
              Explore Game Servers
              <ChevronRight className="w-5 h-5 ml-1 opacity-0 group-hover:opacity-100 transform group-hover:translate-x-1 transition-all duration-300" />
            </a>
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default HeroSection;
