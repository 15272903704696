import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { ChevronRight, Home, Gamepad, Server, Database, Save, PlusSquare, Network, HardDrive, Wifi, Gamepad2 } from 'lucide-react';
import Faq from "../components/Faq";
import Panel from "../components/Panel";
import { BsDiscord } from "react-icons/bs";

const packages = {
  budget: [
    {
      title: "Creeper",
      description: "Ryzen 7 5700G (2 vCores)",
      price: "$7",
      sale: false,
      salePrice: "$5.50",
      link: "https://billing.virtue-host.com/store/essential-minecraft/4-gb",
      image: "https://cdn.virtue-host.com/plan-logos/Creeper.webp",
      features: [
        { icon: <Server className="w-5 h-5" />, text: "4 GB DDR4 RAM" },
        { icon: <HardDrive className="w-5 h-5" />, text: "40 GB NVMe SSD" },
        { icon: <Wifi className="w-5 h-5" />, text: "1 GBit/s Connection" },
        { icon: <Database className="w-5 h-5" />, text: "1 Database" },
        { icon: <Network className="w-5 h-5" />, text: "3 Allocations" },
        { icon: <Save className="w-5 h-5" />, text: "1 Included Backup" },
        { icon: <PlusSquare className="w-5 h-5" />, text: "Create 0 Additional Servers" }
      ]
    },
    {
      title: "Enderman",
      description: "Ryzen 7 5700G (2 vCores)",
      price: "$10.50",
      sale: false,
      salePrice: "$8.25",
      link: "https://billing.virtue-host.com/store/essential-minecraft/6-gb",
      image: "https://cdn.virtue-host.com/plan-logos/Enderman.webp",
      features: [
        { icon: <Server className="w-5 h-5" />, text: "6 GB DDR4 RAM" },
        { icon: <HardDrive className="w-5 h-5" />, text: "60 GB NVMe SSD" },
        { icon: <Wifi className="w-5 h-5" />, text: "1 GBit/s Connection" },
        { icon: <Database className="w-5 h-5" />, text: "2 Database" },
        { icon: <Network className="w-5 h-5" />, text: "3 Allocations" },
        { icon: <Save className="w-5 h-5" />, text: "1 Included Backup" },
        { icon: <PlusSquare className="w-5 h-5" />, text: "Create 0 Additional Servers" }
      ]
    },
    {
      title: "Skeleton",
      description: "Ryzen 7 5700G (2.5 vCores)",
      price: "$14",
      sale: false,
      salePrice: "$11",
      link: "https://billing.virtue-host.com/store/essential-minecraft/8-gb",
      image: "https://cdn.virtue-host.com/plan-logos/Skeleton.webp",
      features: [
        { icon: <Server className="w-5 h-5" />, text: "8 GB DDR4 RAM" },
        { icon: <HardDrive className="w-5 h-5" />, text: "80 GB NVMe SSD" },
        { icon: <Wifi className="w-5 h-5" />, text: "1 GBit/s Connection" },
        { icon: <Database className="w-5 h-5" />, text: "2 Database" },
        { icon: <Network className="w-5 h-5" />, text: "3 Allocations" },
        { icon: <Save className="w-5 h-5" />, text: "1 Included Backup" },
        { icon: <PlusSquare className="w-5 h-5" />, text: "Create 1 Additional Servers" }
      ]
    },
    {
      title: "Magma",
      description: "Ryzen 7 5700G (3 vCores)",
      price: "$21",
      sale: false,
      salePrice: "$16.50",
      link: "https://billing.virtue-host.com/store/essential-minecraft/12-gb",
      image: "https://cdn.virtue-host.com/plan-logos/Magma_Cube.webp",
      features: [
        { icon: <Server className="w-5 h-5" />, text: "12 GB DDR4 RAM" },
        { icon: <HardDrive className="w-5 h-5" />, text: "120 GB NVMe SSD" },
        { icon: <Wifi className="w-5 h-5" />, text: "1 GBit/s Connection" },
        { icon: <Database className="w-5 h-5" />, text: "3 Database" },
        { icon: <Network className="w-5 h-5" />, text: "4 Allocations" },
        { icon: <Save className="w-5 h-5" />, text: "1 Included Backup" },
        { icon: <PlusSquare className="w-5 h-5" />, text: "Create 2 Additional Servers" }
      ]
    },
    {
      title: "Zombie",
      description: "Ryzen 7 5700G (4 vCores)",
      price: "$28",
      sale: false,
      salePrice: "$22",
      link: "https://billing.virtue-host.com/store/essential-minecraft/16-gb",
      image: "https://cdn.virtue-host.com/plan-logos/Zombie.webp",
      features: [
        { icon: <Server className="w-5 h-5" />, text: "16 GB DDR4 RAM" },
        { icon: <HardDrive className="w-5 h-5" />, text: "125 GB NVMe SSD" },
        { icon: <Wifi className="w-5 h-5" />, text: "1 GBit/s Connection" },
        { icon: <Database className="w-5 h-5" />, text: "3 Database" },
        { icon: <Network className="w-5 h-5" />, text: "5 Allocations" },
        { icon: <Save className="w-5 h-5" />, text: "1 Included Backup" },
        { icon: <PlusSquare className="w-5 h-5" />, text: "Create 3 Additional Servers" }
      ]
    }
  ],
  performance: [
    {
      title: "Creeper+",
      description: "Ryzen 9 5950X (2 vCores)",
      price: "$8",
      sale: false,
      salePrice: null,
      link: "https://billing.virtue-host.com/store/performance-minecraft/4-gb",
      image: "https://cdn.virtue-host.com/plan-logos/Creeper.webp",
      features: [
        { icon: <Server className="w-5 h-5" />, text: "4 GB DDR4 RAM" },
        { icon: <HardDrive className="w-5 h-5" />, text: "80 GB NVMe SSD" },
        { icon: <Wifi className="w-5 h-5" />, text: "1 GBit/s Connection" },
        { icon: <Database className="w-5 h-5" />, text: "2 Database" },
        { icon: <Network className="w-5 h-5" />, text: "3 Allocations" },
        { icon: <Save className="w-5 h-5" />, text: "1 Included Backup" },
        { icon: <PlusSquare className="w-5 h-5" />, text: "Create 0 Additional Servers" }
      ]
    },
    {
      title: "Enderman+",
      description: "Ryzen 9 5950X (2 vCores)",
      price: "$12",
      sale: false,
      salePrice: null,
      link: "https://billing.virtue-host.com/store/performance-minecraft/6-gb",
      image: "https://cdn.virtue-host.com/plan-logos/Enderman.webp",
      features: [
        { icon: <Server className="w-5 h-5" />, text: "6 GB DDR4 RAM" },
        { icon: <HardDrive className="w-5 h-5" />, text: "120 GB NVMe SSD" },
        { icon: <Wifi className="w-5 h-5" />, text: "1 GBit/s Connection" },
        { icon: <Database className="w-5 h-5" />, text: "2 Database" },
        { icon: <Network className="w-5 h-5" />, text: "3 Allocations" },
        { icon: <Save className="w-5 h-5" />, text: "1 Included Backup" },
        { icon: <PlusSquare className="w-5 h-5" />, text: "Create 0 Additional Servers" }
      ]
    },
    {
      title: "Skeleton+",
      description: "Ryzen 9 5950X (2.5 vCores)",
      price: "$16",
      sale: false,
      salePrice: null,
      link: "https://billing.virtue-host.com/store/performance-minecraft/8-gb",
      image: "https://cdn.virtue-host.com/plan-logos/Skeleton.webp",
      features: [
        { icon: <Server className="w-5 h-5" />, text: "8 GB DDR4 RAM" },
        { icon: <HardDrive className="w-5 h-5" />, text: "160 GB NVMe SSD" },
        { icon: <Wifi className="w-5 h-5" />, text: "1 GBit/s Connection" },
        { icon: <Database className="w-5 h-5" />, text: "2 Database" },
        { icon: <Network className="w-5 h-5" />, text: "3 Allocations" },
        { icon: <Save className="w-5 h-5" />, text: "1 Included Backup" },
        { icon: <PlusSquare className="w-5 h-5" />, text: "Create 1 Additional Servers" }
      ]
    },
    {
      title: "Magma+",
      description: "Ryzen 9 5950X (3 vCores)",
      price: "$24",
      sale: false,
      salePrice: null,
      link: "https://billing.virtue-host.com/store/performance-minecraft/12-gb",
      image: "https://cdn.virtue-host.com/plan-logos/Magma_Cube.webp",
      features: [
        { icon: <Server className="w-5 h-5" />, text: "12 GB DDR4 RAM" },
        { icon: <HardDrive className="w-5 h-5" />, text: "240 GB NVMe SSD" },
        { icon: <Wifi className="w-5 h-5" />, text: "1 GBit/s Connection" },
        { icon: <Database className="w-5 h-5" />, text: "3 Database" },
        { icon: <Network className="w-5 h-5" />, text: "4 Allocations" },
        { icon: <Save className="w-5 h-5" />, text: "1 Included Backup" },
        { icon: <PlusSquare className="w-5 h-5" />, text: "Create 2 Additional Servers" }
      ]
    },
    {
      title: "Zombie+",
      description: "Ryzen 9 5950X (4 vCores)",
      price: "$32",
      sale: false,
      salePrice: null,
      link: "https://billing.virtue-host.com/store/performance-minecraft/16-gb",
      image: "https://cdn.virtue-host.com/plan-logos/Zombie.webp",
      features: [
        { icon: <Server className="w-5 h-5" />, text: "16 GB DDR4 RAM" },
        { icon: <HardDrive className="w-5 h-5" />, text: "320 GB NVMe SSD" },
        { icon: <Wifi className="w-5 h-5" />, text: "1 GBit/s Connection" },
        { icon: <Database className="w-5 h-5" />, text: "3 Database" },
        { icon: <Network className="w-5 h-5" />, text: "5 Allocations" },
        { icon: <Save className="w-5 h-5" />, text: "1 Included Backup" },
        { icon: <PlusSquare className="w-5 h-5" />, text: "Create 3 Additional Servers" },
        { icon: <BsDiscord className="w-5 h-5" />, text: "Free Discord Lite+ Hosting Package"}
      ]
    }
  ]
};

const PackageCard = ({ pkg }) => (
  <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
    <div className="bg-virtue-darker-accent/50 border border-virtue-border rounded-3xl shadow-xl overflow-hidden hover:border-virtue-primary-green/30 transition-all duration-300">
      <div className="p-8">
        <div className="flex items-center justify-between">
          <h3 className="text-2xl text-virtue-text-white font-bold">{pkg.title}</h3>
          <img src={pkg.image} alt={pkg.title} className="w-16 h-16 object-contain" />
        </div>
        <p className="text-virtue-text-gray mb-6">{pkg.description}</p>
        <div className="text-4xl font-bold mb-6">
          {pkg.sale ? (
            <>
              <span className="line-through text-virtue-text-muted text-3xl">{pkg.price}</span>
              <span className="text-virtue-primary-green ml-2">{pkg.salePrice}</span>
            </>
          ) : (
            <span className="text-virtue-primary-green">{pkg.price}</span>
          )}
          <span className="text-sm font-normal text-virtue-text-muted">/month</span>
        </div>
        <ul className="mb-8">
          {pkg.features.map((feature, idx) => (
            <li key={idx} className="flex items-center text-virtue-text-gray mb-4">
              <span className="text-virtue-text-gray">{feature.icon}</span>
              <span className="ml-3">{feature.text}</span>
            </li>
          ))}
        </ul>
        <a
          href={pkg.link}
          className="block w-full py-4 px-6 text-center font-bold text-virtue-text-white bg-virtue-primary-green/75 rounded-full hover:bg-virtue-secondary-green transition duration-200"
        >
          Select Plan
        </a>
      </div>
    </div>
  </div>
);

const MinecraftHosting = () => {
  const [activeTab, setActiveTab] = useState("budget");

  return (
    <>
      <Helmet>
        <title>Minecraft Hosting | Virtue Host</title>
        <meta
          name="description"
          content="Experience top-tier Minecraft server hosting with Virtue Host. Tailored solutions for smooth gameplay and seamless management."
        />
      </Helmet>

      {/* Hero Section */}
      <section className="px-4 py-24 bg-virtue-background">
        <div className="grid max-w-screen-xl mx-auto lg:gap-8 xl:gap-0 lg:grid-cols-12">
          <div className="mr-auto place-self-center lg:col-span-7">
            <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse text-sm text-virtue-text-gray">
              <li className="inline-flex items-center">
                <a href="/" className="inline-flex items-center font-medium hover:text-virtue-primary-green">
                  <Home className="w-4 h-4 mr-2.5" />
                  Home
                </a>
              </li>
              <li>
                <div className="flex items-center">
                  <ChevronRight className="w-4 h-4 text-virtue-text-muted mx-1" />
                  <a href="/games" className="inline-flex items-center font-medium hover:text-virtue-primary-green">
                    <Gamepad2 className="w-4 h-4 mr-2" />
                    Games
                  </a>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <ChevronRight className="w-4 h-4 text-virtue-text-muted mx-1" />
                  <span className="text-virtue-text-white">Minecraft</span>
                </div>
              </li>
            </ol>
            <h1 className="text-4xl font-extrabold leading-tight mb-4 mt-10 md:text-5xl xl:text-6xl text-virtue-text-white">
              Minecraft <span className="text-virtue-primary-green">Hosting</span>
            </h1>
            <p className="mb-6 text-lg font-semibold lg:mb-8 lg:text-xl text-virtue-text-gray">
              Elevate your Minecraft experience with Virtue Host's premium hosting solutions.
              Enjoy seamless performance, expert support, and unparalleled gaming adventures.
            </p>
            <a
              href="#packages"
              className="inline-flex items-center justify-center text-virtue-text-white hover:bg-virtue-secondary-green focus:ring-4 focus:outline-none font-bold rounded-3xl text-md px-5 py-3 bg-virtue-primary-green/75 focus:ring-virtue-primary-green/20"
            >
              View Plans
              <svg
                className="w-4 h-4 ml-2 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </a>
          </div>

          <div className="hidden lg:flex lg:col-span-5 lg:justify-end lg:items-center">
            <img
              src="https://cdn.virtue-host.com/game-covers/minecraft.webp"
              alt="Minecraft Server Hosting"
              className="rounded-xl w-full max-w-md h-auto object-cover"
            />
          </div>
        </div>
      </section>

      {/* Packages Section */}
      <section id="packages" className="py-20 bg-virtue-background">
        <div className="container mx-auto px-4 max-w-screen-xl">
          <h2 className="text-4xl font-bold mb-16 text-virtue-text-white text-center">
            Choose Your <span className="text-virtue-primary-green">Perfect Plan</span>
          </h2>

          <div className="flex justify-center mb-12">
            <div className="bg-virtue-darker-accent/50 border border-virtue-border p-1 rounded-full">
              {["budget", "performance"].map((tab) => (
                <button
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  className={`px-6 py-3 font-bold rounded-full transition-all duration-300 ${
                    activeTab === tab
                      ? "bg-virtue-primary-green/75 text-virtue-text-white"
                      : "bg-transparent text-virtue-text-gray hover:bg-virtue-hover"
                  }`}
                >
                  {tab === "budget" ? "Essential Plans" : "Performance Plans"}
                </button>
              ))}
            </div>
          </div>

          <div className="flex flex-wrap -mx-4">
            {packages[activeTab].map((pkg) => (
              <PackageCard key={pkg.title} pkg={pkg} />
            ))}
          </div>
        </div>
      </section>

      <Panel />
      <Faq />
    </>
  );
};

export default MinecraftHosting;