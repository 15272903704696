import React from "react";
import { Helmet } from "react-helmet";
import { ChevronRight, Home, Gamepad, CpuIcon, EthernetPort, DatabaseBackupIcon, Database, HardDrive, Wifi } from 'lucide-react';
import Faq from "../components/Faq";
import Panel from "../components/Panel";

const packages = [
  {
    title: "Lvl I",
    description: "Ryzen 9 5950x (3 vCores)",
    price: "$24",
    link: "https://billing.virtue-host.com/store/ark-survival-evolved/lvl-l",
    image: "https://cdn.virtue-host.com/plan-logos/ceratosaurus.webp",
    features: [
      { icon: <CpuIcon className="w-5 h-5" />, text: "12 GB DDR4 RAM" },
      { icon: <HardDrive className="w-5 h-5" />, text: "240 GB NVMe SSD" },
      { icon: <Wifi className="w-5 h-5" />, text: "1 GBit/s Connection" },
      { icon: <Database className="w-5 h-5" />, text: "3 Databases" },
      { icon: <EthernetPort className="w-5 h-5" />, text: "3 Allocations" },
      { icon: <DatabaseBackupIcon className="w-5 h-5" />, text: "1 Included Backup" },
    ],
  },
  {
    title: "Lvl II",
    description: "Ryzen 9 5950x (4 vCores)",
    price: "$32",
    link: "https://billing.virtue-host.com/store/ark-survival-evolved/lvl-ll",
    image: "https://cdn.virtue-host.com/plan-logos/Pal_Metal_Armor.webp",
    features: [
      { icon: <CpuIcon className="w-5 h-5" />, text: "16 GB DDR4 RAM" },
      { icon: <HardDrive className="w-5 h-5" />, text: "320 GB NVMe SSD" },
      { icon: <Wifi className="w-5 h-5" />, text: "1 GBit/s Connection" },
      { icon: <Database className="w-5 h-5" />, text: "3 Databases" },
      { icon: <EthernetPort className="w-5 h-5" />, text: "3 Allocations" },
      { icon: <DatabaseBackupIcon className="w-5 h-5" />, text: "1 Included Backup" },
    ],
  },
];

const PackageCard = ({ pkg }) => (
  <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
    <div className="bg-virtue-darker-accent/50 border border-virtue-border rounded-3xl shadow-xl overflow-hidden hover:border-virtue-primary-green/30 transition-all duration-300">
      <div className="p-8">
        <div className="flex items-center justify-between">
          <h3 className="text-2xl font-bold text-virtue-text-white">
            {pkg.title}
          </h3>
          <img
            src={pkg.image}
            alt={pkg.title}
            className="w-16 h-16 object-contain"
          />
        </div>
        <p className="text-virtue-text-gray mb-6">{pkg.description}</p>
        <div className="text-4xl text-virtue-primary-green font-bold mb-6">
          {pkg.price}
          <span className="text-sm font-normal text-virtue-text-muted">
            /month
          </span>
        </div>
        <ul className="mb-8">
          {pkg.features.map((feature, idx) => (
            <li
              key={idx}
              className="flex items-center mb-4 text-virtue-text-gray"
            >
              <span className="text-virtue-primary-gray">{feature.icon}</span>
              <span className="ml-3">{feature.text}</span>
            </li>
          ))}
        </ul>
        <a
          href={pkg.link}
          className="block w-full py-4 px-6 text-center font-bold text-virtue-text-white bg-virtue-primary-green/75 rounded-full hover:bg-virtue-secondary-green transition duration-200"
        >
          Select Plan
        </a>
      </div>
    </div>
  </div>
);

const SurvivalEvolvedPage = () => {
  return (
    <div className="bg-virtue-background text-virtue-text-white min-h-screen">
      <Helmet>
        <title>ARK: Survival Evolved Hosting | Virtue Host</title>
      </Helmet>

      {/* Hero Section */}
      <section className="px-4 py-24 bg-virtue-background">
        <div className="grid max-w-screen-xl mx-auto lg:gap-8 xl:gap-0 lg:grid-cols-12">
          <div className="mr-auto place-self-center lg:col-span-7">
            <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse text-sm text-virtue-text-gray">
              <li className="inline-flex items-center">
                <a href="/" className="inline-flex items-center font-medium hover:text-virtue-primary-green">
                  <Home className="w-4 h-4 mr-2.5" />
                  Home
                </a>
              </li>
              <li>
                <div className="flex items-center">
                  <ChevronRight className="w-4 h-4 text-virtue-text-muted mx-1" />
                  <a href="/games" className="inline-flex items-center font-medium hover:text-virtue-primary-green">
                    <Gamepad className="w-4 h-4 mr-2" />
                    Games
                  </a>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <ChevronRight className="w-4 h-4 text-virtue-text-muted mx-1" />
                  <span className="text-virtue-text-white">ARK: Survival Evolved</span>
                </div>
              </li>
            </ol>
            <h1 className="text-4xl font-extrabold leading-tight mb-4 mt-10 md:text-5xl xl:text-6xl text-virtue-text-white">
              ARK: Survival Evolved{" "}
              <span className="text-virtue-primary-green">Hosting</span>
            </h1>
            <p className="mb-6 text-lg font-semibold lg:mb-8 lg:text-xl text-virtue-text-gray">
              Experience the ultimate survival gaming experience with our high-performance ARK: Survival Evolved hosting solutions. Dominate the wilderness, forge alliances, and conquer your foes in a relentless battle for survival. Our powerful servers ensure smooth gameplay, reliable performance, and unparalleled support.
            </p>
            <a
              href="https://billing.virtue-host.com/submitticket.php/"
              className="inline-flex items-center justify-center text-virtue-text-white hover:bg-virtue-secondary-green focus:ring-4 focus:outline-none font-bold rounded-3xl text-md px-5 py-3 bg-virtue-primary-green/75 focus:ring-virtue-primary-green/20"
            >
              Speak to Sales
              <svg
                className="w-4 h-4 ml-2 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </a>
          </div>

          <div className="hidden lg:flex lg:col-span-5 lg:justify-end lg:items-center">
            <img
              src="https://cdn.virtue-host.com/game-covers/ArkSurvivalEvolved.webp"
              alt="ARK: Survival Evolved Gameplay"
              className="rounded-xl w-full max-w-md h-auto object-cover"
            />
          </div>
        </div>
      </section>

      {/* Packages Section */}
      <section className="py-20 bg-virtue-background">
        <div className="container mx-auto px-4 max-w-screen-xl">
          <h2 className="text-4xl font-bold mb-16 text-center">
            Choose Your{" "}
            <span className="text-virtue-primary-green">Perfect Plan</span>
          </h2>
          <div className="flex flex-wrap -mx-4">
            {packages.map((pkg, index) => (
              <PackageCard key={index} pkg={pkg} />
            ))}
          </div>
        </div>
      </section>

      <Panel />
      <Faq />
    </div>
  );
};

export default SurvivalEvolvedPage;