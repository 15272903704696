import React from "react";
import { Helmet } from "react-helmet";
import {
  AlertCircle,
  CreditCard,
  RefreshCcw,
  Shield,
  UserX,
  FileWarning,
  Users,
  Lock,
  Bell,
  HardDrive,
  HeadphonesIcon,
  AlertTriangle,
  Mail,
} from "lucide-react";

const Section = ({ icon: Icon, title, children }) => (
  <div className="mb-6 p-6 rounded-2xl bg-virtue-darker-accent/50 border border-virtue-border hover:border-virtue-primary-green/30 transition-all duration-300">
    <div className="flex items-center gap-4 mb-4">
      <Icon className="h-8 w-8 text-virtue-primary-green" />
      <h2 className="text-xl font-semibold text-virtue-text-white">{title}</h2>
    </div>
    <div className="text-virtue-text-gray space-y-4">{children}</div>
  </div>
);

const TermsOfService = () => {
  return (
    <div className="min-h-screen bg-virtue-background text-virtue-text-white">
      <Helmet>
        <title>Terms Of Service | Virtue Host</title>
        <meta
          name="description"
          content="Terms of Service for Virtue Host - Comprehensive hosting solutions for Minecraft, Game Servers and Website hosting needs."
        />
      </Helmet>

      <div className="container mx-auto py-10 px-4 sm:py-16 sm:px-6 max-w-screen-xl">
        {/* Header Section */}
        <div className="text-center mb-12 mt-16">
          <h1 className="text-4xl font-extrabold mb-4">
            <span className="text-transparent bg-clip-text bg-virtue-primary-green">
              Terms of Service
            </span>
          </h1>
          <p className="text-2xl font-semibold text-virtue-text-gray max-w-2xl mx-auto">
            Our comprehensive terms governing the use of Virtue Host services
          </p>
        </div>

        {/* Last Updated Alert */}
        <div className="mb-8 p-4 border border-alertnotice rounded-2xl bg-virtue-darker-accent/50 text-virtue-text-white">
          <div className="flex items-center gap-2">
            <AlertCircle className="h-5 w-5 text-alertnotice" />
            <h2 className="text-lg font-semibold text-alertnotice">
              Last Updated: October 21, 2024
            </h2>
          </div>
          <p className="mt-2 text-virtue-text-gray">
            These terms constitute a legally binding agreement between you
            ("Customer") and Virtue Host ("Company"). Please review them
            carefully.
          </p>
        </div>

        {/* Main Content Sections */}
        <div className="space-y-6">
          <Section icon={Shield} title="Introduction">
            <p>
              Welcome to Virtue Host! Before using our services, please review
              our Terms of Service ("Agreement") carefully.
            </p>
            <p>
              These terms govern the relationship between you ("You,"
              "Customer") and Virtue Host ("Us," "We," "Company").
            </p>
            <ul className="list-disc list-inside space-y-2 marker:text-virtue-primary-green">
              <li>
                By using our services, you agree to be bound by these Terms of
                Service.
              </li>
              <li>
                We may cancel, suspend, or terminate any service at our
                discretion. We will notify you and provide reasons for such
                actions. Refunds will be considered on a case-by-case basis.
              </li>
              <li>
                We may modify these Terms of Service at any time without prior
                notice.
              </li>
              <li>
                You are responsible for reviewing the latest Terms of Service
                periodically.
              </li>
              <li>
                Failure to comply with the updated Terms of Service may result
                in termination of your service.
              </li>
            </ul>
          </Section>

          <Section icon={CreditCard} title="Payment">
            <p>
              Our payment terms are structured to ensure clear communication and
              fair handling of services:
            </p>
            <ul className="list-disc list-inside space-y-2">
              <li>
                Invoice Generation: Invoices are generated 10 days before the
                due date.
              </li>
              <li>
                Payment Attempt: We will attempt to capture the payment 7 days
                before the due date. If the capture fails, the service will be
                suspended 1 day after the due date, but payment will restore
                access to the service.
              </li>
              <li>
                Payment Reminders:
                <ul className="list-disc list-inside ml-6 mt-2">
                  <li>First unpaid reminder: 5 days before the due date</li>
                  <li>First overdue reminder: 1 day after the due date</li>
                  <li>Second overdue reminder: 2 days after the due date</li>
                </ul>
              </li>
              <li>
                Service Suspension: Services will be suspended if payment is not
                received 1 day after the due date.
              </li>
              <li>
                Service Termination: Services will be terminated 3 days after
                the due date, and all associated data will be permanently
                deleted if payment is not made.
              </li>
            </ul>
          </Section>

          <Section icon={RefreshCcw} title="Refunds & Disputes">
            <ul className="list-disc list-inside space-y-2">
              <li>
                Refund Policy: We offer refunds for all payments within 48 hours
                of the original transaction.
              </li>
              <li>
                Refund Processing: Refunds are issued to the original payer and
                are processed only upon confirmation of an associated invoice ID
                linked to the payment gateway. The refunded amount will exclude
                tax fees charged by the payment gateway.
              </li>
              <li>Non-refundable Items: Add-ons are non-refundable.</li>
              <li>
                Chargebacks: Initiating a chargeback will result in automatic
                closure of your account and termination of services, with all
                associated data being permanently deleted. Details of the
                dispute will be provided to the Gateway provider as evidence
                against the client's actions.
              </li>
              <li>
                Avoiding Chargebacks: To avoid a chargeback, it is recommended
                to open a support ticket in advance to discuss your concerns
                with a Virtue Host staff member and determine if you are
                eligible for a refund.
              </li>
            </ul>
          </Section>

          <Section icon={UserX} title="Cancellations">
            <ul className="list-disc list-inside space-y-2">
              <li>
                Service Cancellation: If you do not wish to continue your
                service, you must submit a cancellation request through the
                Virtue Host client area.
              </li>
              <li>
                Assistance with Cancellations: If you need assistance with
                submitting a cancellation request, please contact our support
                team for help.
              </li>
              <li>
                Refund Policy: Please review our refund policy before submitting
                a cancellation request, as certain services may not be eligible
                for refunds.
              </li>
              <li>
                Effective Date: Cancellations will be effective at the end of
                the current billing cycle unless otherwise specified. No
                pro-rated refunds will be issued for partial billing periods.
              </li>
              <li>
                Data Retention: Upon cancellation, all data associated with the
                service will be permanently deleted. Ensure you have backups of
                any important data before proceeding with the cancellation.
              </li>
              <li>
                Confirmation: Once your cancellation request is processed, you
                will receive a confirmation email. If you do not receive this
                email, please contact our support team to verify the
                cancellation status.
              </li>
            </ul>
          </Section>

          <Section icon={FileWarning} title="Usage Restrictions">
            <ul className="list-disc list-inside space-y-2">
              <li>
                Prohibited Uses: You may not use our services for any illegal or
                unauthorized purpose. This includes, but is not limited to,
                activities that violate any local, state, or federal law.
              </li>
              <li>
                Account Sharing: Accounts must not be shared with unauthorized
                users. Each account must be accessed by only one user.
              </li>
              <li>
                Resource Abuse: You may not use excessive amounts of server
                resources, including bandwidth, storage, and processing power,
                that could affect other users.
              </li>
              <li>
                Spam and Malware: Sending spam or distributing malware is
                strictly prohibited.
              </li>
              <li>
                Service Interruption: Actions that result in disruption of
                service for other users are prohibited.
              </li>
            </ul>
          </Section>

          <Section icon={AlertTriangle} title="Abuse">
            <p>
              We take abuse seriously and have established clear procedures for
              handling such situations:
            </p>
            <ul className="list-disc list-inside space-y-2">
              <li>
                Reporting Abuse: To report abuse on the Virtue Host network,
                please contact us at abuse@virtue-host.com. Include all relevant
                details to assist in the investigation.
              </li>
              <li>
                Response to Abuse Complaints: Any abuse complaints regarding
                your services will be forwarded to you and will require
                immediate attention. We may suspend your services until abuse
                reports are resolved.
              </li>
              <li>
                Types of Abuse: The following actions constitute abuse and are
                strictly prohibited:
                <ul className="list-disc list-inside ml-6 mt-2">
                  <li>Spamming or sending unsolicited emails</li>
                  <li>Distribution of malware or malicious software</li>
                  <li>Unauthorized access to networks or systems</li>
                  <li>Conducting illegal activities</li>
                  <li>Harassment or abuse of our staff or other users</li>
                </ul>
              </li>
              <li>
                Preventive Measures: We implement monitoring systems and regular
                security audits to detect and prevent abuse.
              </li>
              <li>
                Cooperation with Authorities: We cooperate with law enforcement
                and regulatory authorities to investigate and respond to abuse
                complaints.
              </li>
            </ul>
          </Section>

          <Section icon={Lock} title="Security">
            <p>
              At Virtue Host, safeguarding user privacy is our top priority:
            </p>
            <ul className="list-disc list-inside space-y-2">
              <li>
                Staff NDAs: Every team member is mandated to sign a
                Non-Disclosure Agreement (NDA), ensuring the confidentiality of
                user information.
              </li>
              <li>
                Account Security: If a user chooses to share their account
                details, Virtue Host cannot be held accountable for any
                subsequent incidents. It is the sole responsibility of the
                client/user to maintain the security of their accounts.
              </li>
              <li>
                Security Requirements: Clients are tasked with enhancing the
                security of their accounts by implementing either a strong
                password or enabling two-factor authentication.
              </li>
            </ul>
          </Section>

          <Section icon={Bell} title="Privacy">
            <ul className="list-disc list-inside space-y-2">
              <li>
                Access Logs: For security purposes, our website stores access
                logs with details for every visitor, including:
                <ul className="list-disc list-inside ml-6 mt-2">
                  <li>Browser details</li>
                  <li>IP address</li>
                  <li>Date and time of access</li>
                  <li>Pages visited</li>
                  <li>Referrer URL</li>
                </ul>
              </li>
              <li>
                Cookies: Our website uses cookies for:
                <ul className="list-disc list-inside ml-6 mt-2">
                  <li>Maintaining your login session</li>
                  <li>Personalizing your user experience</li>
                  <li>Tracking and analyzing website usage</li>
                </ul>
              </li>
              <li>
                Data Security Measures:
                <ul className="list-disc list-inside ml-6 mt-2">
                  <li>SSL encryption for data transmission</li>
                  <li>Regular security audits</li>
                  <li>Access controls and authentication</li>
                </ul>
              </li>
              <li>
                Data Retention: Inactive client data will be deleted after one
                year of inactivity.
              </li>
              <li>
                User Rights: You have the right to:
                <ul className="list-disc list-inside ml-6 mt-2">
                  <li>Access and obtain a copy of your personal data</li>
                  <li>Request correction or deletion of your data</li>
                  <li>Withdraw consent for data processing</li>
                </ul>
              </li>
            </ul>
          </Section>

          <Section icon={Users} title="Affiliates">
            <ul className="list-disc list-inside space-y-2">
              <li>
                Withdrawal Options: Affiliate withdrawals can be made to your
                Virtue Host credit balance or via PayPal.
              </li>
              <li>
                Referral Credits: Successful referrals will credit 10% of the
                referred purchase to your affiliate account. This credit will be
                available for withdrawal 30 days after the successful
                transaction.
              </li>
              <li>
                Program Participation: By participating in our affiliate
                program, you agree to adhere to the terms and conditions set
                forth. Any violation may result in termination of your affiliate
                status and forfeiture of any accumulated credits.
              </li>
              <li>
                Promotional Materials: Affiliates are provided with a range of
                promotional materials to help drive referrals. These materials
                must be used as provided without modification unless approved by
                Virtue Host.
              </li>
              <li>
                Prohibited Activities: The following activities are prohibited
                and may result in the termination of your affiliate account:
                <ul className="list-disc list-inside ml-6 mt-2">
                  <li>Spamming or sending unsolicited emails</li>
                  <li>Misleading or deceptive marketing practices</li>
                  <li>Using unauthorized promotional materials</li>
                  <li>Any form of fraud or abuse</li>
                </ul>
              </li>
            </ul>
          </Section>

          <Section icon={HardDrive} title="Backup Policy">
            <ul className="list-disc list-inside space-y-2">
              <li>
                Client Responsibility: Clients must regularly create and manage
                their backups. Virtue Host is not liable for any data loss.
              </li>
              <li>
                Client-Managed Backups: Clients must back up their website data
                using the provided tools. Virtue Host is not responsible for
                lost data due to failure to back up.
              </li>
              <li>
                Automated Offsite Backups: Virtue Host performs automated
                backups every 15 days per node for game servers. These are
                offsite backups to storage servers in locations of our nodes,
                for disaster recovery only.
              </li>
              <li>
                Storage Limits: Clients must not exceed their plan's storage
                limits for backups.
              </li>
              <li>
                No Guarantee: Virtue Host does not guarantee backup availability
                or integrity. Clients should maintain additional backups
                elsewhere.
              </li>
            </ul>
          </Section>

          <Section icon={HeadphonesIcon} title="Support">
            <p>We provide comprehensive support through multiple channels:</p>
            <ul className="list-disc list-inside space-y-2">
              <li>
                Support Hours:
                <ul className="list-disc list-inside ml-6 mt-2">
                  <li>
                    Discord: 24/7 with 1-hour response for critical issues, 6
                    hours for non-critical issues
                  </li>
                  <li>Client Area: 24/7 with same response time guarantees</li>
                </ul>
              </li>
              <li>
                Contact Methods:
                <ul className="list-disc list-inside ml-6 mt-2">
                  <li>Email: support@virtue-host.com</li>
                  <li>Ticket Center: Available in Client Area</li>
                  <li>Discord Tickets: Available on Discord</li>
                </ul>
              </li>
            </ul>
          </Section>

          {/* Contact Section */}
          <div className="mt-8 p-6 rounded-2xl bg-virtue-darker-accent/50 border border-virtue-border hover:border-virtue-primary-green/30 transition-all duration-300">
            <div className="flex items-center gap-4 mb-4">
              <Mail className="h-8 w-8 text-virtue-primary-green" />
              <h2 className="text-2xl font-semibold text-virtue-text-white">
                Need Assistance?
              </h2>
            </div>
            <div className="text-virtue-text-gray space-y-2">
              <p>
                General Support:{" "}
                <a
                  href="mailto:support@virtue-host.com"
                  className="text-virtue-primary-green hover:text-virtue-secondary-green transition-colors duration-300"
                >
                  support@virtue-host.com
                </a>
              </p>
              <p>
                Abuse Reports:{" "}
                <a
                  href="mailto:abuse@virtue-host.com"
                  className="text-virtue-primary-green hover:text-virtue-secondary-green transition-colors duration-300"
                >
                  abuse@virtue-host.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
