import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import GamesPage from "./pages/GamesPage";
import AupPage from "./pages/AupPage";
import PartnersPage from "./pages/PartnersPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import BotHostingPage from "./pages/BotHostingPage";
import TermsOfService from "./pages/TermsOfService";
import MinecraftPage from "./pages/MinecraftPage";
import CounterStrikePage from "./pages/CounterStrikePage";
import Loader from "./components/utils/Loader";
import PalworldPage from "./pages/PalworldPage";
import GarrysModPage from "./pages/GarrysModPage";
import RustPage from "./pages/RustPage";
import SurvivalEvolvedPage from "./pages/SurvivalEvolved";
import NotFound from "./pages/NotFound";
import SocialIcons from "./components/utils/SocialIcons";
import MotionDiv from "./components/utils/MotionDiv"; // Import the MotionDiv
import SatisfactoryPage from "./pages/SatisfactoryPage";
import CookieConsent from "./components/utils/CookieConsent";
import JobsPage from "./pages/JobsPage";
import AboutUsPage from "./pages/AboutUs";
import HardwarePage from "./pages/HardwareSpecs";
const usePageLoading = () => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const handleRouteChange = () => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    };

    handleRouteChange();

    return () => {
      setLoading(false);
    };
  }, [location]);

  return loading;
};
const PageWrapper = ({ children }) => {
  const loading = usePageLoading();
  
  return (
    <div className={`page-transition ${loading ? 'loading' : ''}`}>
      {loading && <Loader />}
      <div className={loading ? 'content-hidden' : 'content-visible'}>
        {children}
      </div>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Navbar />
      <main>
        <MotionDiv /> {/* Include MotionDiv here for scroll progress */}
        <PageWrapper>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/games" element={<GamesPage />} />
            <Route path="/aup" element={<AupPage />} />
            <Route path="/partners" element={<PartnersPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/bot-hosting" element={<BotHostingPage />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/minecraft" element={<MinecraftPage />} />
            <Route path="/counterstrike" element={<CounterStrikePage />} />
            <Route path="/garrysmod" element={<GarrysModPage />} />
            <Route path="/palworld" element={<PalworldPage />} />
            <Route path="/hardware" element={<HardwarePage/>}/>
            <Route path="/about" element={<AboutUsPage />} />
            <Route path="/jobs" element={<JobsPage />} />
            <Route path="/rust" element={<RustPage />} />
            <Route path="/survival-evolved" element={<SurvivalEvolvedPage />} />
            <Route path="/satisfactory" element={<SatisfactoryPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </PageWrapper>
      </main>
      <SocialIcons />
      <CookieConsent />
      <Footer />
    </Router>
  );
};

export default App;
