import React from "react";
import { Helmet } from "react-helmet";
import { 
  Shield, 
  FileText, 
  Lock, 
  Share2, 
  RefreshCw, 
  UserCheck,
  Cookie,
  AlertCircle,
  Mail
} from "lucide-react";
const Section = ({ icon: Icon, title, children }) => (
  <div className="mb-6 p-6 rounded-2xl bg-virtue-darker-accent/50 border border-virtue-border hover:border-virtue-primary-green/30 transition-all duration-300">
    <div className="flex items-center gap-4 mb-4">
      <Icon className="h-8 w-8 text-virtue-primary-green" />
      <h2 className="text-xl font-semibold text-virtue-text-white">{title}</h2>
    </div>
    <div className="text-virtue-text-gray space-y-4">
      {children}
    </div>
  </div>
);
const Subsection = ({ title, children }) => (
  <>
    <h3 className="text-lg font-semibold text-virtue-primary-green mb-2">{title}</h3>
    {children}
  </>
);

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-virtue-background text-virtue-text-white">
      <Helmet>
        <title>Privacy Policy | Virtue Host</title>
        <meta
          name="description"
          content="Explore our privacy policy at Virtue Host. Learn how we protect your personal information and ensure your privacy."
        />
      </Helmet>

      <div className="container mx-auto py-10 px-4 sm:py-16 sm:px-6 max-w-screen-xl">
        {/* Header Section */}
        <div className="text-center mb-12 mt-16">
          <h1 className="text-4xl font-extrabold mb-4">
            <span className="text-transparent bg-clip-text bg-virtue-primary-green">
              Privacy Policy
            </span>
          </h1>
          <p className="text-2xl font-semibold text-virtue-text-gray max-w-2xl mx-auto">
            Our commitment to protecting your personal information
          </p>
        </div>

        {/* Last Updated Alert */}
        <div className="mb-8 p-4 border border-alertnotice rounded-2xl bg-virtue-darker-accent/50 text-virtue-text-white">
          <div className="flex items-center gap-2">
            <AlertCircle className="h-5 w-5 text-alertnotice" />
            <h2 className="text-lg font-semibold text-alertnotice">Last Updated: October 31, 2024</h2>
          </div>
          <p className="mt-2 text-virtue-text-gray">
            Please review this policy carefully to understand how we collect, use, and protect your personal information. 
            Your continued use of our Services after any modifications constitutes acceptance of those changes.
          </p>
        </div>

        {/* Main Content Sections */}
        <div className="space-y-6">
          <Section icon={Shield} title="Information We Collect">
            <p className="mb-4">We collect information in the following ways:</p>
            <Subsection title="Personal Information:">
              <ul className="list-disc list-inside space-y-2 mb-4 marker:text-virtue-primary-green">
                <li>Name and contact details</li>
                <li>Email address</li>
                <li>Mailing address</li>
                <li>Payment information</li>
                <li>Account credentials</li>
              </ul>
            </Subsection>
            <Subsection title="Non-Personal Information:">
              <ul className="list-disc list-inside space-y-2 marker:text-virtue-primary-green">
                <li>Browser type and version</li>
                <li>Operating system</li>
                <li>IP address</li>
                <li>Internet service provider details</li>
                <li>Website usage patterns</li>
              </ul>
            </Subsection>
          </Section>

          <Section icon={FileText} title="How We Use Your Information">
            <p className="mb-4">Your information helps us provide and improve our services:</p>
            <ul className="list-disc list-inside space-y-2 marker:text-virtue-primary-green">
              <li>Improve customer service and support efficiency</li>
              <li>Personalize your experience based on preferences</li>
              <li>Process payments and fulfill service orders</li>
              <li>Send important service updates and notifications</li>
              <li>Respond to inquiries and support requests</li>
              <li>Analyze service usage patterns</li>
              <li>Create aggregate statistics for business planning</li>
            </ul>
          </Section>

          <Section icon={Cookie} title="Cookies and Analytics">
            <p className="mb-4">We use cookies and similar technologies to enhance your experience:</p>
            <ul className="list-disc list-inside space-y-2">
              <li>Essential cookies for website functionality</li>
              <li>Google Analytics for usage analysis</li>
            </ul>
            <p className="mt-4">
              Google Analytics privacy policy: {" "}
              <a href="https://policies.google.com/privacy" className="text-maingreen hover:underline" target="_blank" rel="noopener noreferrer">
                https://policies.google.com/privacy
              </a>
            </p>
          </Section>

          <Section icon={Lock} title="How We Protect Your Information">
            <p className="mb-4">Your data security is our top priority. We implement:</p>
            <ul className="list-disc list-inside space-y-2">
              <li>SSL encryption for all sensitive data</li>
              <li>Regular security audits and penetration testing</li>
              <li>Strict access controls for authorized personnel</li>
              <li>24/7 system monitoring for suspicious activity</li>
              <li>Secure data storage and backup systems</li>
              <li>Industry-standard security protocols</li>
              <li>Continuous security practice improvements</li>
            </ul>
          </Section>

          <Section icon={Share2} title="Sharing Your Information">
            <p className="mb-4">We maintain strict privacy standards:</p>
            <ul className="list-disc list-inside space-y-2">
              <li>No selling or trading of personal information</li>
              <li>Limited sharing with service providers when necessary</li>
              <li>Sharing only when required by law or legal process</li>
              <li>Anonymous aggregated data may be shared for business purposes</li>
            </ul>
          </Section>

          <Section icon={UserCheck} title="Your Data Protection Rights">
            <p className="mb-4">You have the following rights regarding your data:</p>
            <ul className="list-disc list-inside space-y-2">
              <li>Access your personal data copies</li>
              <li>Correct inaccurate information</li>
              <li>Request data erasure</li>
              <li>Restrict data processing</li>
              <li>Object to processing</li>
              <li>Request data portability</li>
            </ul>
            <p className="mt-4 text-sm">We respond to all rights requests within 30 days.</p>
          </Section>

          <Section icon={RefreshCw} title="Changes to This Policy">
            <p>
              We reserve the right to update this Privacy Policy as needed. Changes will be effective immediately upon posting 
              the revised policy with a new effective date. Your continued use of our Services after any modifications constitutes 
              acceptance of those changes. We encourage you to review this Privacy Policy periodically to stay informed about how 
              we collect, use, and protect your personal information.
            </p>
          </Section>

      {/* Contact Section */}
      <div className="mt-8 p-6 rounded-2xl bg-virtue-darker-accent/50 border border-virtue-border hover:border-virtue-primary-green/30 transition-all duration-300">
            <div className="flex items-center gap-4 mb-4">
              <Mail className="h-8 w-8 text-virtue-primary-green" />
              <h2 className="text-2xl font-semibold text-virtue-text-white">Need Assistance?</h2>
            </div>
            <div className="text-virtue-text-gray space-y-2">
              <p>
                Privacy Inquiries: 
                <a href="mailto:abuse@virtue-host.com" className="text-virtue-primary-green hover:text-virtue-secondary-green transition-colors duration-300 ml-2">
                  abuse@virtue-host.com
                </a>
              </p>
              <p>
                General Contact: 
                <a href="mailto:contact@virtue-host.com" className="text-virtue-primary-green hover:text-virtue-secondary-green transition-colors duration-300 ml-2">
                  contact@virtue-host.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default PrivacyPolicy;