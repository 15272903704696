import React, { memo } from "react";
import { useInView } from "react-intersection-observer";
import {
  Command,
  Building,
  Info,
  Scale,
  ExternalLink,
  Gamepad2,
} from "lucide-react";
import { FaDiscord } from "react-icons/fa";

const footerSections = [
  {
    title: "Services",
    icon: <Gamepad2 className="w-5 h-5" />,
    links: [
      { text: "Minecraft Servers", href: "/minecraft" },
      { text: "Game Servers", href: "/games" },
      { text: "Bot Hosting", href: "/bot-hosting" },
    ],
  },
  {
    title: "Clients",
    icon: <Command className="w-5 h-5" />,
    links: [
      {
        text: "Game Panel",
        href: "https://panel.virtue-host.com/",
        isExternal: true,
      },
      {
        text: "Client Area",
        href: "https://billing.virtue-host.com/",
        isExternal: true,
      },
      {
        text: "Create a Ticket",
        href: "https://billing.virtue-host.com/submitticket.php/",
        isExternal: true,
      },
      {
        text: "Knowledgebase",
        href: "https://billing.virtue-host.com/submitticket.php/",
        isExternal: true,
      },
    ],
  },
  {
    title: "Company",
    icon: <Building className="w-5 h-5" />,
    links: [
      {
        text: "Network Status",
        href: "https://status.virtue-host.com/",
        isExternal: true,
      },
      { text: "Our Story", href: "/about" },
      {
        text: "Contact Us",
        href: "https://billing.virtue-host.com/contact.php",
        isExternal: true,
      },
    ],
  },
  {
    title: "Resources",
    icon: <Info className="w-5 h-5" />,
    links: [
      {
        text: "Affiliate Program",
        href: "https://billing.virtue-host.com/affiliates.php",
        isExternal: true,
      },
      { text: "Hardware Details", href: "/hardware" },
      { text: "Jobs", href: "/jobs" },
      { text: "Our Partners", href: "/partners" },
    ],
  },
  {
    title: "Legal",
    icon: <Scale className="w-5 h-5" />,
    links: [
      { text: "Terms of Service", href: "/terms-of-service" },
      { text: "Privacy Policy", href: "/privacy-policy" },
      { text: "Acceptable Use Policy", href: "/aup" },
    ],
  },
];

const socialLinks = [
  {
    icon: <FaDiscord className="w-5 h-5" />,
    href: "https://discord.com/invite/UR5BD98Wn6",
    label: "Join our Discord community",
  },
];

const FooterLink = memo(({ href, text, isExternal }) => (
  <a
    href={href}
    className="group flex items-center gap-2 text-virtue-text-gray hover:text-virtue-text-white transition-all duration-300 hover:translate-x-1 active:scale-98"
    target={isExternal ? "_blank" : undefined}
    rel={isExternal ? "noopener noreferrer" : undefined}
    aria-label={isExternal ? `${text} (opens in new tab)` : undefined}
  >
    <span className="relative">
      {text}
      <span
        className="absolute -bottom-0.5 left-0 w-0 h-0.5 bg-gradient-to-r from-virtue-primary-green to-virtue-accent-teal transition-all duration-300 group-hover:w-full"
        aria-hidden="true"
      />
    </span>
    {isExternal && (
      <ExternalLink
        size={12}
        className="opacity-0 -translate-y-1 group-hover:opacity-100 group-hover:translate-y-0 transition-all duration-300"
        aria-hidden="true"
      />
    )}
  </a>
));

const SocialLink = memo(({ href, icon, label }) => (
  <a
    href={href}
    aria-label={label}
    target="_blank"
    rel="noopener noreferrer"
    className="p-2 text-virtue-text-gray hover:text-virtue-primary-green transition-all duration-300 
              group hover:scale-110 hover:rotate-3 active:scale-95"
  >
    <span
      className="opacity-75 group-hover:opacity-100 transition-opacity duration-300"
      aria-hidden="true"
    >
      {icon}
    </span>
  </a>
));

const FooterSection = memo(({ title, icon, links, index }) => {
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
    rootMargin: "50px",
  });

  return (
    <div
      ref={ref}
      className={`flex flex-col transform transition-all duration-500 ease-out
        ${inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-5'}`}
      style={{ transitionDelay: `${index * 100}ms` }}
    >
      <div
        className="flex items-center mb-6 group"
        role="heading"
        aria-level={2}
      >
        <div className="relative mr-3">
          <div className="w-10 h-10 rounded-lg bg-virtue-card border border-virtue-border 
            group-hover:border-virtue-primary-green/20 flex items-center justify-center 
            text-virtue-primary-green transition-colors duration-300">
            <span aria-hidden="true">{icon}</span>
          </div>
          <div className="absolute inset-0 rounded-lg bg-virtue-primary-green/5 blur-sm 
            opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            aria-hidden="true"
          />
        </div>
        <h3 className="text-lg font-semibold text-virtue-text-white 
          group-hover:text-virtue-primary-green transition-colors duration-300">
          {title}
        </h3>
      </div>

      <div className="flex flex-col space-y-3" role="list">
        {links.map((link, i) => (
          <div key={i} role="listitem">
            <FooterLink {...link} />
          </div>
        ))}
      </div>
    </div>
  );
});

const Footer = () => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const currentYear = new Date().getFullYear();

  return (
    <footer
      ref={ref}
      className="bg-virtue-background relative mt-auto border-t border-virtue-border"
      role="contentinfo"
      aria-label="Site footer"
    >
      <div className="absolute inset-0 bg-virtue-background" aria-hidden="true" />

      <div className="relative container mx-auto px-6 py-16 max-w-screen-xl">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-12">
          {footerSections.map((section, index) => (
            <FooterSection key={section.title} {...section} index={index} />
          ))}
        </div>

        <div
          className={`mt-12 pt-8 border-t border-virtue-border transform transition-all duration-500 ease-out
            ${inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-5'}`}
          style={{ transitionDelay: '500ms' }}
        >
          <div className="flex flex-col sm:flex-row justify-between items-center gap-6">
            <div className="flex items-center gap-3">
              <img
                src="/logo.png"
                alt="Virtue Host Logo"
                className="w-8 h-8 rounded"
                width={32}
                height={32}
                loading="lazy"
              />
              <div className="flex flex-col">
                <p className="text-virtue-text-gray text-sm">
                  © {currentYear} Virtue Host. All rights reserved.
                </p>
                <p className="text-virtue-text-muted text-xs">
                  Experience Virtue. Elevate Hosting.
                </p>
              </div>
            </div>

            <div className="flex items-center gap-4" role="list" aria-label="Social media links">
              {socialLinks.map((link, index) => (
                <div key={index} role="listitem">
                  <SocialLink {...link} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default memo(Footer);