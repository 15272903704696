import React, { memo, useEffect } from "react";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";

const ScrollProgressBar = memo(({
  height = "4px",
  colors = {
    from: "#22C55E", // virtue-primary-green
    via: "#22C55E", // virtue-primary-green
    to: "#2DD4BF" // virtue-accent-teal
  },
  springConfig = {
    stiffness: 120,
    damping: 40,
    restDelta: 0.001
  }
}) => {
  const { scrollYProgress } = useScroll({
    offset: ["start start", "end end"],
    // Add smooth: true for smoother scrolling
    smooth: true
  });

  // Reset scroll position on page load/refresh
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // More stable spring animation with lower mass
  const scaleX = useSpring(scrollYProgress, {
    ...springConfig,
    mass: 0.1,
    // Add restSpeed for more stability
    restSpeed: 0.05
  });

  const backgroundColor = useTransform(
    scaleX,
    [0, 0.5, 1],
    [colors.from, colors.via, colors.to]
  );

  const glowOpacity = useTransform(
    scaleX,
    [0, 0.5, 1],
    [0.2, 0.3, 0.4]
  );

  const width = useTransform(
    scaleX,
    [0, 1],
    ["0%", "100%"]
  );

  return (
    <>
      {/* Background track */}
      <div
        className="fixed top-0 left-0 right-0 z-40"
        style={{ height }}
      >
        <div className="relative w-full h-full">
          <div
            className="absolute inset-0 bg-virtue-darker-accent/90"
            style={{ borderRadius: height }}
          />
          
          <div
            className="absolute inset-0 overflow-hidden"
            style={{ borderRadius: height }}
          >
            {[...Array(5)].map((_, i) => (
              <div
                key={i}
                className="absolute h-px bg-virtue-border/30"
                style={{
                  top: `${20 * i}%`,
                  left: 0,
                  right: 0,
                  transform: `translateX(${Math.random() * 100}%)`
                }}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Main progress bar */}
      <motion.div
        className="fixed top-0 left-0 z-50 origin-left"
        style={{
          height,
          width,
          backgroundColor,
          borderRadius: height
        }}
        initial={{ opacity: 0, scaleX: 0 }}
        animate={{ opacity: 1, scaleX: 1 }}
        transition={{ 
          duration: 0.3,
          ease: "easeOut"
        }}
      >
        {/* Gradient overlay */}
        <div
          className="absolute inset-0"
          style={{
            background: 'linear-gradient(90deg, transparent 30%, rgba(255,255,255,0.1), transparent 70%)',
            borderRadius: height
          }}
        />

        {/* Glitch effect container */}
        <div className="absolute inset-0 overflow-hidden">
          <motion.div
            className="absolute inset-0"
            animate={{
              backgroundPosition: ['0% 0%', '100% 0%'],
            }}
            transition={{
              duration: 1.5,
              repeat: Infinity,
              repeatType: 'reverse',
              ease: 'linear'
            }}
            style={{
              backgroundImage: `
                repeating-linear-gradient(
                  90deg,
                  transparent,
                  transparent 20px,
                  rgba(255,255,255,0.1) 20px,
                  rgba(255,255,255,0.1) 21px
                )
              `
            }}
          />
        </div>

        {/* Leading edge glow */}
        <motion.div
          className="absolute right-0 h-full"
          style={{
            width: '8px',
            background: colors.to,
            filter: 'blur(2px)',
            opacity: glowOpacity,
            borderRadius: height
          }}
        />

        {/* Subtle scanning line */}
        <motion.div
          className="absolute top-0 right-0 h-full w-20"
          animate={{
            x: [0, -80, 0],
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            ease: 'linear'
          }}
          style={{
            background: `linear-gradient(90deg, transparent, ${colors.to}20, transparent)`,
          }}
        />
      </motion.div>
    </>
  );
});

ScrollProgressBar.displayName = 'ScrollProgressBar';

export default ScrollProgressBar;