import React, { memo } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import {
  Trophy,
  Server,
  Zap,
  Award,
} from "lucide-react";

const providers = [
  {
    name: "Virtue Host",
    rating: 100,
    cpu: "Ryzen 9 5950X",
    cpuScore: "3470",
    ram: "16GB DDR4",
    storage: "320GB NVMe SSD",
    price: "$32",
    isPrimary: true,
  },
  {
    name: "Bloom Host",
    rating: 85,
    cpu: "Ryzen 9 5950X",
    cpuScore: "3470",
    ram: "16GB DDR4",
    storage: "300GB NVMe SSD",
    price: "$48",
  },
  {
    name: "Sparked Host",
    rating: 80,
    cpu: "Ryzen 7 7700",
    cpuScore: "4059",
    ram: "16GB DDR4",
    storage: "250GB NVMe SSD",
    price: "$64",
  },
  {
    name: "PebbleHost",
    rating: 78,
    cpu: "Ryzen 7 5800X",
    cpuScore: "3445",
    ram: "16GB DDR4",
    storage: "Unmetered NVMe SSD",
    price: "$62.50",
  },
  {
    name: "Shockbyte",
    rating: 72,
    cpu: "Xeon E-2386",
    cpuScore: "2382",
    ram: "16GB DDR4",
    storage: "Unmetered NVMe SSD",
    price: "$63.99",
  },
  {
    name: "Apex Hosting",
    rating: 68,
    cpu: "Ryzen 9 5900X",
    cpuScore: "3469",
    ram: "16GB DDR4",
    storage: "Unmetered NVMe SSD",
    price: "$71.99",
  },
];


const ComparisonHeader = memo(() => (
  <tr className="bg-virtue-darker-accent/80">
    <th className="px-6 py-4 text-left text-sm font-medium text-virtue-text-gray">Provider</th>
    <th className="px-6 py-4 text-left text-sm font-medium text-virtue-text-gray">Rating</th>
    <th className="px-6 py-4 text-left text-sm font-medium text-virtue-text-gray">Processor</th>
    <th className="px-6 py-4 text-left text-sm font-medium text-virtue-text-gray">Memory</th>
    <th className="px-6 py-4 text-left text-sm font-medium text-virtue-text-gray">Storage</th>
    <th className="px-6 py-4 text-left text-sm font-medium text-virtue-text-gray">Monthly</th>
  </tr>
));

const RatingBar = memo(({ rating, isPrimary, inView, delay }) => (
  <div className="relative">
    <div className="flex items-center gap-3">
      <div className="relative w-32 h-2 bg-virtue-card rounded-full overflow-hidden">
        <motion.div
          className={`h-full rounded-full ${
            isPrimary
              ? "bg-virtue-primary-green"
              : rating >= 81
              ? "bg-virtue-primary-green/75"
              : rating >= 75
              ? "bg-alertnotice"
              : "bg-warningnotice"
          }`}
          initial={{ width: 0 }}
          animate={{ width: inView ? `${rating}%` : 0 }}
          transition={{ duration: 1, delay: delay * 0.1, ease: "easeOut" }}
        />
      </div>
      <span className={`text-sm font-medium ${
        isPrimary ? "text-virtue-primary-green" : "text-virtue-text-gray"
      }`}>
        {rating}%
      </span>
    </div>
  </div>
));

const ComparisonRow = memo(({ provider, index, inView }) => (
  <tr
    className={`group transition-all duration-300 hover:bg-virtue-hover relative
      ${provider.isPrimary ? "bg-virtue-card/30 hover:bg-virtue-hover" : "hover:bg-virtue-hover/30"}
      transform transition-all duration-500 ease-out
      ${inView 
        ? 'opacity-100 translate-y-0' 
        : 'opacity-0 translate-y-5'}`}
    style={{ transitionDelay: `${index * 100}ms` }}
  >
    <td className="px-6 py-4">
      <div className="flex items-center gap-2">
        {provider.isPrimary ? (
          <div className="p-1.5 rounded-lg bg-virtue-primary-green/10 text-virtue-primary-green">
            <Trophy className="w-4 h-4" />
          </div>
        ) : (
          <div className="p-1.5 rounded-lg bg-virtue-darker-accent">
            <Server className="w-4 h-4 text-virtue-text-gray" />
          </div>
        )}
        <div>
          <span className={`font-medium ${
            provider.isPrimary ? "text-virtue-text-white" : "text-virtue-text-gray"
          }`}>
            {provider.name}
          </span>
        </div>
      </div>
    </td>
    <td className="px-6 py-4">
      <RatingBar
        rating={provider.rating}
        isPrimary={provider.isPrimary}
        inView={inView}
        delay={index}
      />
    </td>
    <td className="px-6 py-4">
      <div className="space-y-1">
        <div className={`text-sm font-medium ${
          provider.isPrimary ? "text-virtue-text-white" : "text-virtue-text-gray"
        }`}>
          {provider.cpu}
        </div>
        <div className="flex items-center gap-1 text-xs text-virtue-text-muted">
          <Zap className="w-3 h-3" />
          Score: {provider.cpuScore}
        </div>
      </div>
    </td>
    <td className="px-6 py-4">
      <span className={`font-medium ${
        provider.isPrimary ? "text-virtue-text-white" : "text-virtue-text-gray"
      }`}>
        {provider.ram}
      </span>
    </td>
    <td className="px-6 py-4">
      <span className={`font-medium ${
        provider.isPrimary ? "text-virtue-text-white" : "text-virtue-text-gray"
      }`}>
        {provider.storage}
      </span>
    </td>
    <td className="px-6 py-4">
      <div className="flex items-baseline gap-1">
        <span className={`text-lg font-bold ${
          provider.isPrimary ? "text-virtue-primary-green" : "text-virtue-text-gray"
        }`}>
          {provider.price}
        </span>
        <span className="text-xs text-virtue-text-muted">/mo</span>
      </div>
    </td>
  </tr>
));

const ComparisonSection = () => {
  const { ref: headerRef, inView: headerInView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const { ref: tableRef, inView: tableInView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <section className="py-24 bg-virtue-background relative overflow-hidden">
      <div className="container mx-auto px-6 relative max-w-screen-xl">
        {/* Section header */}
        <div
          ref={headerRef}
          className={`text-center mb-16 transform transition-all duration-700 ease-out
            ${headerInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-5'}`}
        >
          <div className="inline-flex items-center gap-2 px-4 py-2 rounded-xl bg-virtue-darker-accent border border-virtue-border mb-6">
            <Award className="w-5 h-5 text-virtue-primary-green" />
            <span className="text-base font-medium text-virtue-text-white">
              Performance Comparison
            </span>
          </div>

          <h2 className="text-4xl md:text-5xl font-bold mb-6">
            <span className="text-virtue-text-white">Why Choose </span>
            <span className="text-transparent bg-clip-text bg-virtue-primary-green">
              Virtue Host
            </span>
          </h2>

          <p className="text-xl text-virtue-text-gray max-w-3xl mx-auto leading-relaxed mb-8">
            Compare our premium game hosting solutions with other providers.
            Experience superior performance at competitive prices.
          </p>
        </div>

        {/* Comparison table */}
        <div
          ref={tableRef}
          className={`relative transform transition-all duration-700 ease-out
            ${tableInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-5'}`}
        >
          <div className="overflow-hidden rounded-xl border border-virtue-border bg-virtue-darker-accent/50 backdrop-blur-sm">
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-virtue-border">
                <thead>
                  <ComparisonHeader />
                </thead>
                <tbody className="divide-y divide-virtue-border">
                  {providers.map((provider, index) => (
                    <ComparisonRow
                      key={provider.name}
                      provider={provider}
                      index={index}
                      inView={tableInView}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default memo(ComparisonSection);