import React, { memo } from "react";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import {
  Users,
  Target,
  ShieldCheck,
  Award,
  Cpu,
  MessageSquare,
  ArrowRight,
  Server,
  Globe,
} from "lucide-react";

const Section = memo(({ icon: Icon, title, children, index }) => {
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      className="h-full"
    >
      <div className="h-full rounded-xl bg-slate-900/50 border border-slate-800 hover:border-green-500/30 transition-all duration-300">
        <div className="p-6">
          <div className="flex items-center gap-4 mb-6">
            <div className="p-3 rounded-lg bg-green-500/10">
              <Icon className="h-6 w-6 text-green-400" />
            </div>
            <h2 className="text-xl font-bold text-white">{title}</h2>
          </div>
          <div className="text-gray-300 leading-relaxed">{children}</div>
        </div>
      </div>
    </motion.div>
  );
});

const StatCard = memo(({ icon: Icon, title, value }) => (
  <motion.div
    className="p-6 rounded-xl bg-slate-900/50 border border-slate-800"
    whileHover={{ scale: 1.02 }}
  >
    <div className="flex items-center gap-4">
      <div className="p-3 rounded-lg bg-green-500/10">
        <Icon className="w-6 h-6 text-green-400" />
      </div>
      <div>
        <p className="text-gray-400 text-sm">{title}</p>
        <p className="text-2xl font-bold text-white">{value}</p>
      </div>
    </div>
  </motion.div>
));

const AboutUsPage = () => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <div className="min-h-screen bg-virtue-background">
      <Helmet>
        <title>About Us | Virtue Host</title>
        <meta
          name="description"
          content="Learn about Virtue Host's mission to provide exceptional game hosting services, our values, and our commitment to the gaming community."
        />
      </Helmet>

      {/* Hero Section */}
      <section className="relative py-24 overflow-hidden">
        <div className="container mx-auto px-6 relative">
          <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 20 }}
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            className="text-center mb-16"
          >
            <div className="inline-flex items-center gap-2 px-4 py-2 rounded-xl bg-virtue-darker-accent border border-virtue-border mb-6">
              <Award className="w-5 h-5 text-virtue-primary-green" />
              <span className="text-base font-medium text-virtue-text-white">
                Our Story
              </span>
            </div>

            <h1 className="text-4xl md:text-5xl font-bold mb-6">
              <span className="text-virtue-text-white">About </span>
              <span className="text-transparent bg-clip-text bg-virtue-primary-green">
                Virtue Host
              </span>
            </h1>

            <p className="text-xl text-virtue-text-gray max-w-3xl mx-auto leading-relaxed mb-2">
              Powering the future of game hosting with enterprise-grade
              infrastructure
            </p>
            <p className="text-virtue-text-muted">Established in 2024</p>
          </motion.div>

          {/* Stats Grid */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-4xl mx-auto mb-16">
            <StatCard icon={Server} title="Hosted Games" value="7+" />
            <StatCard icon={Globe} title="Global Locations" value="2" />
            <StatCard icon={Users} title="Active Users" value="100+" />
          </div>
        </div>
      </section>

      {/* Main Content */}
      <section className="container max-w-screen-xl mx-auto px-6 pb-24">
        <div className="grid md:grid-cols-2 gap-6 mb-12">
          <Section icon={Award} title="Our Story" index={0}>
            <p className="mb-4">
              Virtue Host was founded by BZ, a dedicated gamer since before
              2009, who first discovered his passion for hosting Minecraft
              servers in 2013. After taking a break from gaming & hosting
              Minecraft servers, he returned to Minecraft hosting, only to find
              that most game hosting providers were bogged down by limitations,
              hidden fees, outdated resources, and unreasonable pricing.
            </p>
            <p>
              In 2024, after resuming his hosting journey in 2021, BZ launched
              Virtue Host with a commitment to fair pricing, complete
              transparency, and unparalleled support—redefining what gamers
              should expect from their hosting provider.
            </p>
          </Section>

          <Section icon={Target} title="Our Mission" index={1}>
            <p className="mb-4">
              We're on a mission to revolutionize game hosting by providing:
            </p>
            <ul className="space-y-3">
              {[
                "High-performance, ultra-reliable servers that set the standard for speed and stability",
                "Pricing that actually makes sense",
                "User-friendly control panels that make server management effortless",
                "24/7 support from passionate gamers who truly understand your needs",
              ].map((item, idx) => (
                <li key={idx} className="flex items-start gap-3">
                  <ArrowRight className="w-5 h-5 text-virtue-primary-green shrink-0 mt-0.5" />
                  <span>{item}</span>
                </li>
              ))}
            </ul>
          </Section>

          <Section icon={Cpu} title="Our Technology" index={2}>
            <p className="mb-4">
              We leverage the reliable hardware and infrastructure to deliver
              exceptional performance:
            </p>
            <ul className="space-y-3">
              {[
                "AMD Ryzen processors for superior single-core gaming performance",
                "NVMe SSD storage for lightning-fast transfer speeds",
                "DDR4 or higher RAM for optimal load times",
                "Minimum 1Gbit/s network connections to our nodes",
              ].map((item, idx) => (
                <li key={idx} className="flex items-start gap-3">
                  <ArrowRight className="w-5 h-5 text-virtue-primary-green shrink-0 mt-0.5" />
                  <span>{item}</span>
                </li>
              ))}
            </ul>
          </Section>

          <Section icon={ShieldCheck} title="Our Values" index={3}>
            <div className="space-y-6">
              {[
                {
                  title: "Blazing Performance",
                  desc: "Enterprise-grade servers optimized for speed",
                },
                {
                  title: "Customer Priority",
                  desc: "Support team at your service, always",
                },
                {
                  title: "Zero Hidden Fees",
                  desc: "Simple, transparent pricing guaranteed",
                },
              ].map((value, idx) => (
                <div key={idx} className="flex items-start gap-3">
                  <div className="p-2 rounded-lg bg-virtue-card text-virtue-primary-green">
                    <ShieldCheck className="w-5 h-5" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-virtue-text-white mb-1">
                      {value.title}
                    </h3>
                    <p className="text-virtue-text-gray">{value.desc}</p>
                  </div>
                </div>
              ))}
            </div>
          </Section>
        </div>

        <div className="grid md:grid-cols-2 gap-6">
          <Section icon={Users} title="Our Team" index={4}>
            <p className="mb-6">
              Our team consists of passionate gamers, experienced developers,
              and dedicated support specialists who understand your hosting
              needs firsthand. We're players just like you, committed to
              delivering the best possible gaming experience without breaking
              the bank.
            </p>
            <motion.a
              href="/jobs"
              className="inline-flex items-center gap-2 px-6 py-3 bg-virtue-primary-green/75 text-virtue-text-white font-medium rounded-xl hover:bg-virtue-secondary-green transition-colors duration-300 group"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              Join Our Team
              <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
            </motion.a>
          </Section>

          <Section icon={MessageSquare} title="Get in Touch" index={5}>
            <p className="mb-6">
              Have questions about our services or want to learn more? We'd love
              to hear from you!
            </p>
            <div className="flex flex-wrap gap-4">
              <motion.a
                href="mailto:contact@virtue-host.com"
                className="inline-flex items-center gap-2 px-6 py-3 bg-virtue-primary-green/75 text-virtue-text-white font-medium rounded-xl hover:bg-virtue-secondary-green transition-colors duration-300 group"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                Contact Support
                <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
              </motion.a>
              <motion.a
                href="https://discord.com/invite/UR5BD98Wn6"
                className="inline-flex items-center gap-2 px-6 py-3 bg-virtue-darker-accent text-virtue-text-white font-medium rounded-xl hover:bg-virtue-hover border border-virtue-border hover:border-virtue-primary-green/20 transition-all duration-300 group"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                Join Discord
                <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
              </motion.a>
            </div>
          </Section>
        </div>
      </section>
    </div>
  );
};

export default memo(AboutUsPage);
