import React, { useState, useEffect } from 'react';
import { Cookie } from 'lucide-react';

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showFullText, setShowFullText] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setIsVisible(true);
      setIsMobile(checkIsMobile());
    } else {
      const consentData = JSON.parse(consent);
      if (consentData.acceptAnalytics) {
        initializeGoogleAnalytics();
      }
    }
  }, []);

  const checkIsMobile = () => {
    const userAgent = navigator.userAgent;
    return /iPhone|iPad|iPod|Android/i.test(userAgent);
  };

  const acceptCookies = () => {
    localStorage.setItem('cookieConsent', JSON.stringify({ acceptAnalytics: true, timestamp: Date.now() }));
    setIsVisible(false);
    initializeGoogleAnalytics();
  };

  const declineCookies = () => {
    localStorage.setItem('cookieConsent', JSON.stringify({ acceptAnalytics: false, timestamp: Date.now() }));
    setIsVisible(false);
  };

  const initializeGoogleAnalytics = () => {
    window.dataLayer = window.dataLayer || [];
    window.gtag = function () { window.dataLayer.push(arguments); };
    window.gtag('js', new Date());
    window.gtag('config', 'G-ZBC28NF6RQ');
  };

  if (!isVisible) return null;

  return (
    <div className={`fixed inset-x-0 bottom-0 z-50 bg-virtue-background border-t-2 border-virtue-primary-green ${isMobile ? 'py-4' : 'py-6'}`}>
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between flex-wrap">
          <div className="flex items-center flex-1 w-0">
            <span className="flex p-2 rounded-lg bg-virtue-primary-green">
              <Cookie className="h-6 w-6 text-virtue-background" aria-hidden="true" />
            </span>
            <p className={`ml-3 font-medium text-virtue-text-white ${isMobile ? 'text-sm' : 'text-base'}`}>
              <span>
                We utilize cookies and related technologies to guarantee an optimal experience while browsing our website.{' '}
                <a
                  href="/privacy-policy"
                  className="text-virtue-accent-teal hover:text-virtue-primary-green transition-colors duration-200"
                  onMouseEnter={() => setShowFullText(true)}
                  onMouseLeave={() => setShowFullText(false)}
                >
                  {showFullText || !isMobile ? 'Learn more' : 'Learn more'}
                </a>
              </span>
            </p>
          </div>
          <div className="flex-shrink-0 order-2 sm:order-3 sm:mt-0 sm:w-auto">
            <button
              onClick={acceptCookies}
              className="flex items-center justify-center ml-2 px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-virtue-text-white bg-virtue-primary-green hover:bg-virtue-secondary-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-virtue-background focus:ring-virtue-primary-green transition-colors duration-200"
            >
              Accept
            </button>
          </div>
          <div className="flex-shrink-0 order-3 sm:order-2 sm:mt-0 sm:w-auto ml-3">
            <button
              onClick={declineCookies}
              className="flex items-center justify-center px-4 py-2 border border-virtue-border rounded-md shadow-sm text-sm font-medium text-virtue-text-gray bg-virtue-darker-accent hover:bg-virtue-hover hover:border-virtue-hover-border focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-virtue-background focus:ring-virtue-border transition-colors duration-200"
            >
              Decline
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;