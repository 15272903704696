import React, { useState, useEffect, useRef, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  ChevronDown,
  X,
  Menu,
  ExternalLink,
  Bot,
  FileText,
  Building,
  Home,
  RadarIcon,
  Command,
  Gamepad2,
  Layers,
  Terminal,
} from "lucide-react";

const navItems = [
  { type: "link", label: "Home", href: "/", icon: <Home size={16} /> },
  {
    type: "link",
    label: "Game Servers",
    href: "/games",
    icon: <Gamepad2 size={16} />,
  },
  {
    type: "dropdown",
    label: "Solutions",
    icon: <Layers size={16} />,
    items: [
      { label: "Bot Hosting", href: "/bot-hosting", icon: <Bot size={16} /> },
    ],
  },
  {
    type: "dropdown",
    label: "Company",
    icon: <Building size={16} />,
    items: [
      {
        label: "Server Status",
        href: "https://status.virtue-host.com",
        icon: <RadarIcon size={16} />,
      },
      {
        label: "Terms of Service",
        href: "/terms-of-service",
        icon: <FileText size={16} />,
      },
      {
        label: "Privacy Policy",
        href: "/privacy-policy",
        icon: <FileText size={16} />,
      },
    ],
  },
];

const NavLink = React.memo(({ href, children, icon, external }) => (
  <motion.a
    href={href}
    className="relative flex items-center gap-2 text-virtue-text-gray hover:text-virtue-text-white transition-all duration-300 group py-2 px-3 rounded-md"
    whileHover={{
      backgroundColor: "rgba(30, 41, 59, 0.8)",
      transition: { duration: 0.2 },
    }}
    whileTap={{ scale: 0.98 }}
    {...(external && {
      target: "_blank",
      rel: "noopener noreferrer",
      "aria-label": `${children} (opens in new tab)`,
    })}
  >
    {icon && (
      <span
        className="text-virtue-primary-green group-hover:text-virtue-secondary-green transition-colors duration-200"
        aria-hidden="true"
      >
        {icon}
      </span>
    )}
    <span className="relative z-10 font-medium">
      {children}
      <motion.span
        className="absolute bottom-0 left-0 right-0 h-[2px] bg-gradient-to-r from-virtue-primary-green to-virtue-accent-teal transform origin-left"
        initial={{ scaleX: 0 }}
        whileHover={{ scaleX: 1 }}
        transition={{ duration: 0.3 }}
      />
    </span>
    {external && (
      <ExternalLink size={14} className="ml-1 opacity-75" aria-hidden="true" />
    )}
  </motion.a>
));

const DropdownButton = React.memo(({ label, isOpen, onClick, icon }) => (
  <motion.button
    className="relative flex items-center gap-2 text-virtue-text-gray hover:text-virtue-text-white transition-all duration-300 group py-2 px-3 rounded-md w-full"
    onClick={onClick}
    whileHover={{
      backgroundColor: "rgba(30, 41, 59, 0.8)",
      transition: { duration: 0.2 },
    }}
    whileTap={{ scale: 0.98 }}
    aria-expanded={isOpen}
    aria-haspopup="true"
  >
    {icon && (
      <span
        className="text-virtue-primary-green group-hover:text-virtue-secondary-green transition-colors duration-200"
        aria-hidden="true"
      >
        {icon}
      </span>
    )}
    <span className="relative z-10 font-medium">
      {label}
      <motion.span
        className="absolute bottom-0 left-0 right-0 h-[2px] bg-gradient-to-r from-virtue-primary-green to-virtue-accent-teal transform origin-left"
        initial={{ scaleX: 0 }}
        whileHover={{ scaleX: 1 }}
        transition={{ duration: 0.3 }}
      />
    </span>
    <motion.span
      animate={{ rotate: isOpen ? 180 : 0 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
      className="ml-1"
      aria-hidden="true"
    >
      <ChevronDown size={16} className="text-virtue-text-white" />
    </motion.span>
  </motion.button>
));

const ClientButton = React.memo(({ href, children, icon, primary }) => (
  <motion.a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className={`flex items-center gap-2 px-3 py-3 text-sm font-medium rounded-xl
      transition-all duration-300 backdrop-blur-sm
      ${
        primary
          ? "bg-virtue-primary-green/75 text-virtue-text-white shadow-lg hover:bg-virtue-secondary-green"
          : "bg-virtue-card text-virtue-text-white hover:text-virtue-text-white border border-virtue-border hover:border-virtue-hover-border"
      }`}
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.98 }}
  >
    {icon && (
      <span
        className={
          primary ? "text-virtue-text-white" : "text-virtue-primary-green"
        }
      >
        {icon}
      </span>
    )}
    {children}
    <ExternalLink size={14} className="opacity-50" />
  </motion.a>
));

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const dropdownRefs = useRef({});
  const mobileMenuRef = useRef(null);
  const scrollTimeout = useRef(null);

  const handleScroll = useCallback(() => {
    if (scrollTimeout.current) {
      window.cancelAnimationFrame(scrollTimeout.current);
    }

    scrollTimeout.current = window.requestAnimationFrame(() => {
      setIsScrolled(window.scrollY > 20);
    });
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !Object.values(dropdownRefs.current).some((ref) =>
          ref?.contains(event.target)
        ) &&
        !mobileMenuRef.current?.contains(event.target)
      ) {
        setOpenDropdown(null);
        setMobileMenuOpen(false);
      }
    };

    const handleEscape = (event) => {
      if (event.key === "Escape") {
        setOpenDropdown(null);
        setMobileMenuOpen(false);
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscape);

    return () => {
      if (scrollTimeout.current) {
        window.cancelAnimationFrame(scrollTimeout.current);
      }
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscape);
    };
  }, [handleScroll]);

  const navVariants = {
    hidden: { y: -100, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 20,
      },
    },
  };

  const dropdownVariants = {
    hidden: { opacity: 0, y: -5, scale: 0.95 },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        duration: 0.2,
        ease: "easeOut",
      },
    },
  };

  return (
    <motion.nav
      initial="hidden"
      animate="visible"
      variants={navVariants}
      className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300
        ${
          isScrolled
            ? "bg-virtue-background/95 backdrop-blur-sm border-b border-virtue-border shadow-lg"
            : "bg-virtue-background"
        }`}
      role="navigation"
      aria-label="Main navigation"
    >
      <div className="container mx-auto px-4 py-4 max-w-screen-2xl">
        <div className="flex items-center justify-between">
          {/* Logo */}
          <a
            href="/"
            className="flex items-center gap-3 group"
            aria-label="Virtue Host - Enterprise Game Hosting"
          >
            <div className="relative">
              <img
                className="h-10 w-10 rounded-lg shadow-lg"
                src="https://cdn.virtue-host.com/company/logo.png"
                alt=""
                width={40}
                height={40}
              />
              <div className="absolute inset-0 rounded-lg ring-1 ring-virtue-border" />
            </div>
            <div className="flex flex-col">
              <span className="text-2xl font-bold bg-virtue-text-white bg-clip-text text-transparent">
                Virtue Host
              </span>
              <span className="text-xs text-virtue-primary-green font-medium">
                AMD Ryzen Game Servers
              </span>
            </div>
          </a>

          {/* Desktop Navigation */}
          <div className="hidden lg:flex items-center gap-6 text-lg">
            {navItems.map((item, index) => (
              <div
                key={index}
                className="relative"
                ref={(el) => (dropdownRefs.current[index] = el)}
              >
                {item.type === "link" ? (
                  <NavLink
                    href={item.href}
                    icon={item.icon}
                    external={item.href.startsWith("http")}
                  >
                    {item.label}
                  </NavLink>
                ) : (
                  <>
                    <DropdownButton
                      label={item.label}
                      icon={item.icon}
                      isOpen={openDropdown === index}
                      onClick={() =>
                        setOpenDropdown((prev) =>
                          prev === index ? null : index
                        )
                      }
                    />
                    <AnimatePresence>
                      {openDropdown === index && (
                        <motion.div
                          initial="hidden"
                          animate="visible"
                          exit="hidden"
                          variants={dropdownVariants}
                          className="absolute right-0 mt-2 py-2 bg-virtue-background rounded-xl border border-virtue-border shadow-xl min-w-[250px] ring-1 ring-virtue-primary-green/10"
                          role="menu"
                          aria-orientation="vertical"
                        >
                          {item.items.map((subItem, subIndex) => (
                            <NavLink
                              key={subIndex}
                              href={subItem.href}
                              icon={subItem.icon}
                              external={subItem.href.startsWith("http")}
                            >
                              {subItem.label}
                            </NavLink>
                          ))}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </>
                )}
              </div>
            ))}
          </div>

          {/* Client Area Buttons */}
          <div className="hidden lg:flex items-center gap-3">
            <ClientButton
              href="https://panel.virtue-host.com/"
              icon={<Terminal size={16} />}
            >
              Game Panel
            </ClientButton>
            <ClientButton
              href="https://billing.virtue-host.com/"
              icon={<Command size={16} />}
              primary
            >
              Client Area
            </ClientButton>
          </div>

          {/* Mobile Menu Button */}
          <motion.button
            className="lg:hidden p-2 text-virtue-text-white hover:text-virtue-primary-green transition-colors"
            onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            aria-expanded={isMobileMenuOpen}
            aria-controls="mobile-menu"
            aria-label={isMobileMenuOpen ? "Close menu" : "Open menu"}
          >
            {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </motion.button>
        </div>

        {/* Mobile Menu */}
        <AnimatePresence>
          {isMobileMenuOpen && (
            <motion.div
              id="mobile-menu"
              ref={mobileMenuRef}
              initial={{ opacity: 0, height: 0 }}
              animate={{
                opacity: 1,
                height: "auto",
                transition: {
                  duration: 0.3,
                  ease: "easeOut",
                },
              }}
              exit={{
                opacity: 0,
                height: 0,
                transition: {
                  duration: 0.2,
                  ease: "easeIn",
                },
              }}
              className="lg:hidden mt-4 py-4 border-t border-virtue-border"
            >
              <div className="flex flex-col gap-2">
                {navItems.map((item, index) => (
                  <div key={index}>
                    {item.type === "link" ? (
                      <NavLink
                        href={item.href}
                        icon={item.icon}
                        external={item.href.startsWith("http")}
                      >
                        {item.label}
                      </NavLink>
                    ) : (
                      <>
                        <DropdownButton
                          label={item.label}
                          icon={item.icon}
                          isOpen={openDropdown === index}
                          onClick={() =>
                            setOpenDropdown((prev) =>
                              prev === index ? null : index
                            )
                          }
                        />
                        <AnimatePresence>
                          {openDropdown === index && (
                            <motion.div
                              initial="hidden"
                              animate="visible"
                              exit="hidden"
                              variants={dropdownVariants}
                              className="ml-6 mt-2 space-y-2"
                              role="menu"
                              aria-orientation="vertical"
                            >
                              {item.items.map((subItem, subIndex) => (
                                <NavLink
                                  key={subIndex}
                                  href={subItem.href}
                                  icon={subItem.icon}
                                  external={subItem.href.startsWith("http")}
                                >
                                  {subItem.label}
                                </NavLink>
                              ))}
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </>
                    )}
                  </div>
                ))}
              </div>
              {/* Mobile Client Buttons */}
              <div className="flex flex-col gap-3 mt-6">
                <ClientButton
                  href="https://panel.virtue-host.com/"
                  icon={<Terminal size={16} />}
                >
                  Game Panel
                </ClientButton>
                <ClientButton
                  href="https://billing.virtue-host.com/"
                  icon={<Command size={16} />}
                  primary
                >
                  Client Area
                </ClientButton>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.nav>
  );
};

export default React.memo(Navbar);
