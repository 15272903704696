import React, { memo, useState, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FaDiscord, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { ChevronRight } from 'lucide-react';

const socialLinks = [
  {
    href: "https://discord.com/invite/UR5BD98Wn6",
    Icon: FaDiscord,
    color: "#7289DA",
    label: "Join our Discord server",
  },
  {
    href: "https://www.instagram.com/virtuehost/",
    Icon: FaInstagram,
    color: "#c13584",
    label: "Follow us on Instagram",
  },
  {
    href: "https://www.x.com/virtuehosting",
    Icon: FaTwitter,
    color: "#1DA1F2",
    label: "Follow us on X",
  },
  {
    href: "https://www.youtube.com/@virtuehost",
    Icon: FaYoutube,
    color: "#FF0000",
    label: "Subscribe to our YouTube channel",
  }
];

const SocialIcon = memo(({ href, Icon, color, label, index }) => (
  <motion.a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    aria-label={label}
    initial={{ opacity: 0, x: 20 }}
    animate={{ opacity: 1, x: 0 }}
    exit={{ opacity: 0, x: 20 }}
    transition={{ 
      duration: 0.2,
      delay: index * 0.05,
      ease: "easeOut"
    }}
    whileHover={{ 
      scale: 1.1,
      transition: { duration: 0.2 }
    }}
    whileTap={{ 
      scale: 0.95,
      transition: { duration: 0.1 }
    }}
    className="p-3 rounded-xl hover:bg-virtue-hover transition-all duration-200 relative group"
  >
    <Icon
      size={20}
      style={{ color }}
      className="drop-shadow-lg transition-transform duration-200 group-hover:scale-110"
    />
    {/* Tooltip */}
    <motion.span
      initial={{ opacity: 0, y: 10 }}
      whileHover={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.2 }}
      className="absolute left-full ml-2 px-2 py-1 bg-virtue-darker-accent/90 
                 text-sm text-virtue-text-gray rounded-md whitespace-nowrap
                 pointer-events-none hidden md:block"
    >
      {label}
    </motion.span>
  </motion.a>
));

SocialIcon.displayName = 'SocialIcon';

const SocialIcons = ({ position = "right" }) => {
  const [isOpen, setIsOpen] = useState(true);
  
  const toggleOpen = useCallback(() => {
    setIsOpen(prev => !prev);
  }, []);

  const containerVariants = {
    open: {
      x: 0,
      transition: { 
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    },
    closed: {
      x: position === 'right' ? '100%' : '-100%',
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    }
  };

  return (
    <motion.div
      initial="open"
      animate={isOpen ? "open" : "closed"}
      variants={containerVariants}
      className={`fixed ${position === 'right' ? 'right-0' : 'left-0'} top-1/3 z-40`}
    >
      <div className="relative flex items-center">
        {/* Mobile Toggle */}
        <motion.button
          onClick={toggleOpen}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          className={`md:hidden absolute ${position === 'right' ? '-left-7' : '-right-7'}
            top-1/2 -translate-y-1/2 bg-virtue-card p-2
            ${position === 'right' ? 'rounded-l-lg' : 'rounded-r-lg'}
            shadow-lg border border-virtue-border hover:border-virtue-hover-border
            transition-colors duration-300 hover:bg-virtue-hover`}
          aria-label={isOpen ? "Hide social links" : "Show social links"}
        >
          <motion.div
            animate={{ rotate: isOpen ? 0 : 180 }}
            transition={{ duration: 0.2 }}
          >
            <ChevronRight
              size={16}
              className={`text-virtue-text-gray ${position === 'right' ? '' : 'rotate-180'}`}
            />
          </motion.div>
        </motion.button>

        {/* Icons Container */}
        <AnimatePresence mode="wait">
          <motion.div
            className="flex flex-col gap-2 bg-virtue-darker-accent/80 backdrop-blur p-3
                       border border-virtue-border hover:border-virtue-hover-border
                       shadow-xl rounded-lg md:rounded-xl transition-colors duration-300"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {socialLinks.map((link, index) => (
              <SocialIcon key={link.href} {...link} index={index} />
            ))}
          </motion.div>
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

export default memo(SocialIcons);