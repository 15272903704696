import React from "react";
import { Helmet } from "react-helmet";
import { 
  Cpu, 
  HardDrive, 
  AlertCircle,
  CpuIcon,
  NetworkIcon
} from "lucide-react";
import { FaGlobeAmericas, FaGlobeEurope } from "react-icons/fa";

const RegionSection = ({ title, specs }) => (
  <div className="mb-6 p-6 rounded-2xl bg-virtue-darker-accent border border-virtue-border">
    <h3 className="text-xl font-semibold text-virtue-primary-green mb-4">{title}</h3>
    <div className="space-y-4">
      {specs.map((spec, index) => (
        <div key={index} className="p-4 bg-virtue-card rounded-lg border border-virtue-border">
          <div className="font-semibold text-virtue-text-white mb-2">{spec.location}</div>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 text-virtue-text-gray">
            <div className="flex items-center gap-2">
              <Cpu className="h-4 w-4 text-virtue-primary-green" />
              {spec.cpu}
            </div>
            <div className="flex items-center gap-2">
              <HardDrive className="h-4 w-4 text-virtue-primary-green" />
              {spec.storage}
            </div>
            <div className="flex items-center gap-2">
              <CpuIcon className="h-4 w-4 text-virtue-primary-green" />
              {spec.ram}
            </div>
            <div className="flex items-center gap-2">
              <NetworkIcon className="h-4 w-4 text-virtue-primary-green" />
              {spec.uplink}
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);

const HardwarePage = () => {
  const northAmerica = [
    {
      location: "Bluffdale, Utah",
      cpu: "Ryzen 7 5700G 3.8GHz 8c/16t",
      storage: "1TB NVMe SSD",
      ram: "64GB DDR4 RAM",
      uplink: "1Gbit/s Transfer"
    }
  ];

  const Europe = [
    {
      location: "Heleniski, Finland",
      cpu: "Ryzen 9 5950X 3.4GHz 16c/32t",
      storage: "2TB NVMe SSD",
      ram: "128GB DDR4",
      uplink: "1Gbit/s Transfer"
    }
  ];

  return (
    <div className="min-h-screen bg-virtue-background text-virtue-text-white">
      <Helmet>
        <title>Server Hardware Specifications | Virtue Host</title>
        <meta
          name="description"
          content="Detailed hardware specifications for Virtue Host's high-performance game servers across global regions."
        />
      </Helmet>

      <div className="container mx-auto py-10 px-4 sm:py-16 sm:px-6 max-w-screen-xl">
        {/* Header Section */}
        <div className="text-center mb-12 mt-16">
          <h1 className="text-4xl font-extrabold mb-4 text-virtue-primary-green">
            Server Hardware Specifications
          </h1>
          <p className="text-2xl font-semibold text-virtue-text-gray max-w-2xl mx-auto">
            High-performance infrastructure powering your gaming experience
          </p>
          <p className="mt-4 text-virtue-text-muted">Last Updated: November 13, 2024</p>
        </div>

        {/* Hardware Notice */}
        <div className="mb-8 p-4 border border-virtue-border rounded-2xl bg-virtue-darker-accent">
          <div className="flex items-center gap-2">
            <AlertCircle className="h-5 w-5 text-alertnotice" />
            <h2 className="text-lg font-semibold text-alertnotice">Hardware Commitment</h2>
          </div>
          <p className="mt-2 text-virtue-text-gray">
            We are continuously upgrading our hardware to ensure optimal performance. 
            Specifications may vary slightly by region & package type
          </p>
        </div>

        {/* Main Content */}
        <div className="space-y-8">
          <div className="grid gap-6">
            <div className="p-6 rounded-2xl bg-virtue-darker-accent border border-virtue-border">
              <div className="flex items-center gap-4 mb-4">
                <FaGlobeAmericas className="h-8 w-8 text-virtue-primary-green" />
                <h2 className="text-2xl font-semibold text-virtue-text-white">North America</h2>
              </div>
              <RegionSection specs={northAmerica} />
            </div>

            <div className="p-6 rounded-2xl bg-virtue-darker-accent border border-virtue-border">
              <div className="flex items-center gap-4 mb-4">
                <FaGlobeEurope className="h-8 w-8 text-virtue-primary-green" />
                <h2 className="text-2xl font-semibold text-virtue-text-white">Europe</h2>
              </div>
              <RegionSection specs={Europe} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HardwarePage;