import { React, memo } from "react";
import { useInView } from "react-intersection-observer";
import {
  Cpu,
  Shield,
  Rocket,
  Award,
  HardDrive,
  HeadphonesIcon,
} from "lucide-react";

const features = [
  {
    title: "NVMe Storage",
    description:
      "Experience blazing-fast load times with enterprise-grade NVMe SSDs, delivering unparalleled speed and reliability for your game servers.",
    icon: <HardDrive />,
    bgGradient: "from-mainblue/20 to-virtue-accent-teal/20",
  },
  {
    title: "DDoS Protection",
    description:
      "Enterprise-grade protection against DDoS attacks, including UDP floods, SYN attacks, and volumetric threats, keeping your servers online 24/7.",
    icon: <Shield />,
    bgGradient: "from-warningnotice/20 to-alertnotice/20",
  },
  {
    title: "Instant Setup",
    description:
      "Get your server up and running in minutes with our automated deployment system and intuitive control panel.",
    icon: <Rocket />,
    bgGradient: "from-virtue-primary-green/20 to-virtue-accent-teal/20",
  },
  {
    title: "Premium Hardware",
    description:
      "Built on high-performance AMD Ryzen processors, ensuring optimal performance for even the most demanding games.",
    icon: <Award />,
    bgGradient: "from-alertnotice/20 to-maingreen/20",
  },
  {
    title: "Dedicated Resources",
    description:
      "Guaranteed CPU threads and RAM allocation, ensuring consistent performance without resource contention.",
    icon: <Cpu />,
    bgGradient: "from-mainblue/20 to-virtue-accent-teal/20",
  },
  {
    title: "24/7 Support",
    description:
      "Expert gaming support available around the clock, providing rapid assistance whenever you need it.",
    icon: <HeadphonesIcon />,
    bgGradient: "from-virtue-primary-green/20 to-virtue-accent-teal/20",
  },
];

const FeatureCard = memo(({ title, description, icon, bgGradient, index }) => {
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
    rootMargin: "50px",
  });

  return (
    <div
      ref={ref}
      className={`relative group h-full transform transition-all duration-500 ${
        inView
          ? "opacity-100 translate-y-0 scale-100"
          : "opacity-0 translate-y-8 scale-95"
      }`}
      style={{ transitionDelay: `${index * 100}ms` }}
    >
      {/* Glow effect */}
      <div className="absolute -inset-0.5 bg-gradient-to-r from-virtue-primary-green/20 to-virtue-accent-teal/20 rounded-2xl blur opacity-0 group-hover:opacity-100 transition duration-300" />

      <div className="relative bg-virtue-darker-accent/50 border border-virtue-border p-8 rounded-2xl h-full flex flex-col backdrop-blur-sm group-hover:border-virtue-primary-green/20 transition-colors duration-300">
        {/* Background effects */}
        <div
          className={`absolute inset-0 bg-gradient-to-b ${bgGradient} opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-2xl`}
        />

        {/* Icon */}
        <div className="relative mb-6">
          <div className="w-14 h-14 rounded-xl bg-virtue-card border border-virtue-border group-hover:border-virtue-primary-green/20 shadow-lg flex items-center justify-center transition-all duration-300 hover:scale-105 hover:rotate-3">
            <div className="text-virtue-primary-green w-6 h-6">{icon}</div>
          </div>
        </div>

        {/* Content */}
        <div className="flex-grow space-y-4 relative">
          <h3 className="text-xl font-bold text-virtue-text-white group-hover:text-virtue-primary-green transition-colors duration-300">
            {title}
          </h3>
          <p className="text-virtue-text-gray group-hover:text-virtue-text-white transition-colors duration-300 leading-relaxed">
            {description}
          </p>
        </div>

        {/* Decorative corners */}
        <div className="absolute top-0 left-0 w-8 h-8 border-t border-l border-transparent group-hover:border-virtue-primary-green/20 transition-all duration-300 rounded-tl-xl" />
        <div className="absolute bottom-0 right-0 w-8 h-8 border-b border-r border-transparent group-hover:border-virtue-primary-green/20 transition-all duration-300 rounded-br-xl" />
      </div>
    </div>
  );
});

FeatureCard.displayName = "FeatureCard";

const FeaturesSection = () => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <section className="py-24 bg-virtue-background relative overflow-hidden">
      <div className="container mx-auto px-6 relative max-w-screen-xl">
        {/* Section header */}
        <div
          ref={ref}
          className={`text-center mb-16 transform transition-all duration-700 ${
            inView ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-6"
          }`}
        >
          <div className="inline-flex items-center gap-2 px-4 py-2 rounded-xl bg-virtue-darker-accent border border-virtue-border mb-6">
            <Award className="w-5 h-5 text-virtue-primary-green" />
            <span className="text-base font-medium text-virtue-text-white">
              Enterprise Features
            </span>
          </div>

          <h2 className="text-4xl md:text-5xl font-bold mb-6">
            <span className="text-virtue-text-white">Next-Generation </span>
            <span className="text-transparent bg-clip-text bg-virtue-primary-green">
              Game Hosting
            </span>
          </h2>

          <p className="text-xl text-virtue-text-gray max-w-2xl mx-auto leading-relaxed">
            Experience unparalleled performance with our enterprise-grade
            infrastructure, designed specifically for high-performance game
            servers.
          </p>
        </div>

        {/* Features grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {features.map((feature, index) => (
            <FeatureCard key={feature.title} {...feature} index={index} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default memo(FeaturesSection);
