import React from 'react';
import { motion } from 'framer-motion';
import MapContent from "./Map.jsx";

const GlobalLocations = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15,
        delayChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, ease: "easeOut" }
    }
  };

  return (
    <section className="relative bg-virtue-background py-24 overflow-hidden">
      {/* Background Gradient Effects */}
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,rgba(34,197,94,0.03),transparent_50%)]" />
      
      {/* Grid pattern */}
      <div 
        className="absolute inset-0 opacity-[0.02]"
        style={{
          backgroundImage: `linear-gradient(rgba(255,255,255,0.1) 1px, transparent 1px),
                           linear-gradient(90deg, rgba(255,255,255,0.1) 1px, transparent 1px)`,
          backgroundSize: '50px 50px'
        }}
      />

      <div className="relative z-10 container mx-auto px-6">
        <motion.div
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="max-w-screen-7xl mx-auto"
        >
          {/* Section Header */}
          <motion.div variants={itemVariants} className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-4xl md:text-5xl font-bold mb-6">
              <span className="text-transparent bg-clip-text bg-virtue-primary-green">
                Global Locations
              </span>
            </h2>
            <p className="text-xl text-virtue-text-gray font-medium">
              Select from our global locations for swift download speeds and minimal ping
            </p>
          </motion.div>

          {/* Map Component */}
          <motion.div 
            variants={itemVariants}
            className="relative rounded-2xl overflow-hiddenshadow-xl"
          >
            <MapContent />
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default GlobalLocations;