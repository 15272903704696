import React, { memo } from "react";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { 
  AlertCircle, 
  Shield, 
  Network, 
  Lock, 
  Bell, 
  FileWarning,
  Gavel,
  ArrowRight,
  ShieldAlert,
  Gamepad,
  Bot,
  Globe
} from "lucide-react";

const Section = memo(({ icon: Icon, title, children, index }) => {
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
    >
      <div className="h-full rounded-xl bg-slate-900/50 border border-slate-800 hover:border-green-500/30 transition-all duration-300">
        <div className="p-6">
          <div className="flex items-center gap-4 mb-6">
            <div className="p-3 rounded-lg bg-green-500/10">
              <Icon className="h-6 w-6 text-green-400" />
            </div>
            <h2 className="text-xl font-bold text-white">{title}</h2>
          </div>
          <div className="text-gray-300 leading-relaxed">
            {children}
          </div>
        </div>
      </div>
    </motion.div>
  );
});

const ProhibitedCategory = memo(({ title, rules }) => (
  <motion.div
    className="p-6 rounded-xl bg-slate-800/50 border border-slate-700"
    whileHover={{ scale: 1.02 }}
  >
    <h3 className="text-lg font-bold text-white mb-4">{title}</h3>
    <ul className="space-y-3">
      {rules.map((rule, idx) => (
        <li key={idx} className="flex items-start gap-3">
          <ArrowRight className="w-4 h-4 text-green-400 shrink-0 mt-1" />
          <span className="text-gray-300">{rule}</span>
        </li>
      ))}
    </ul>
  </motion.div>
));

const ServiceRules = memo(({ title, icon: Icon, rules }) => (
  <motion.div
    className="p-6 rounded-xl bg-slate-800/50 border border-slate-700"
    whileHover={{ scale: 1.02 }}
  >
    <div className="flex items-center gap-3 mb-4">
      <div className="p-2 rounded-lg bg-green-500/10">
        <Icon className="w-5 h-5 text-green-400" />
      </div>
      <h3 className="text-lg font-bold text-white">{title}</h3>
    </div>
    <ul className="space-y-3">
      {rules.map((rule, idx) => (
        <li key={idx} className="flex items-start gap-3">
          <ArrowRight className="w-4 h-4 text-green-400 shrink-0 mt-1" />
          <span className="text-gray-300">{rule}</span>
        </li>
      ))}
    </ul>
  </motion.div>
));

const AupPage = () => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true
  });

  return (
    <div className="min-h-screen bg-slate-950">
      <Helmet>
        <title>Acceptable Usage Policy | Virtue Host</title>
        <meta
          name="description"
          content="Our acceptable use policy outlining the terms and conditions for using Virtue Host services."
        />
      </Helmet>

      {/* Hero Section */}
      <section className="relative py-24 overflow-hidden">
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-green-500/10 via-slate-950 to-slate-950" />
        
        <div className="container mx-auto px-6 relative">
          <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 20 }}
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            className="text-center mb-16"
          >
            <div className="inline-flex items-center gap-2 px-4 py-2 rounded-xl bg-slate-900 border border-slate-800 mb-6">
              <Shield className="w-5 h-5 text-green-400" />
              <span className="text-base font-medium text-white">Usage Policy</span>
            </div>

            <h1 className="text-4xl md:text-5xl font-bold mb-6">
              <span className="text-white">Acceptable </span>
              <span className="text-transparent bg-clip-text bg-virtue-primary-green">
                Use Policy
              </span>
            </h1>
            
            <p className="text-xl text-gray-400 max-w-3xl mx-auto leading-relaxed mb-2">
              Our commitment to maintaining a secure, reliable, and ethical hosting environment
            </p>
            <p className="text-gray-500">Last Updated: November 13, 2024</p>
          </motion.div>

          {/* Important Notice */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            transition={{ delay: 0.2 }}
            className="max-w-4xl mx-auto"
          >
            <div className="p-6 rounded-xl bg-slate-900/50 border border-orange-500/30 backdrop-blur-sm">
              <div className="flex items-center gap-3 mb-3">
                <AlertCircle className="h-5 w-5 text-orange-400" />
                <h2 className="text-lg font-semibold text-orange-400">Important Notice</h2>
              </div>
              <p className="text-gray-300">
                All users must comply with this policy to maintain their services. This AUP may be updated periodically, and users are responsible for reviewing and complying with any changes.
              </p>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Main Content */}
      <section className="container  max-w-screen-xl mx-auto px-6 pb-24">
        {/* Key Sections */}
        <div className="grid md:grid-cols-2 gap-6 mb-12">
          <Section icon={Shield} title="Objective and Scope" index={0}>
            <p>
              This policy establishes guidelines for responsible use of Virtue Host's services,
              ensuring security, reliability, and compliance with legal obligations. Access is restricted 
              to individuals aged 13 and above, with parental consent required for those under 18.
            </p>
          </Section>

          <Section icon={Network} title="Network Usage and Misuse" index={1}>
            <ul className="space-y-3">
              {[
                "No unauthorized network scanning or probing",
                "Avoid excessive bandwidth consumption",
                "No DDoS attacks or malicious traffic",
                "Maintain reasonable resource usage",
                "No interference with services of others"
              ].map((item, idx) => (
                <li key={idx} className="flex items-start gap-3">
                  <ArrowRight className="w-4 h-4 text-green-400 shrink-0 mt-1" />
                  <span>{item}</span>
                </li>
              ))}
            </ul>
          </Section>

          <Section icon={Lock} title="Security Requirements" index={2}>
            <ul className="space-y-3">
              {[
                "No unauthorized access attempts",
                "Report security vulnerabilities responsibly",
                "Maintain secure credentials",
                "No distribution of malicious software",
                "No unauthorized vulnerability testing"
              ].map((item, idx) => (
                <li key={idx} className="flex items-start gap-3">
                  <ArrowRight className="w-4 h-4 text-green-400 shrink-0 mt-1" />
                  <span>{item}</span>
                </li>
              ))}
            </ul>
          </Section>

          <Section icon={Bell} title="Monitoring & Compliance" index={3}>
            <p>
              We actively monitor our network to ensure policy compliance and maintain service quality.
              This includes logging traffic, inspecting communications, and analyzing usage patterns.
              Violations may result in immediate service suspension or termination.
            </p>
          </Section>
        </div>

        {/* Prohibited Activities */}
        <div className="mb-12">
          <div className="mb-6">
            <h2 className="text-2xl font-bold text-white mb-2">Prohibited Activities</h2>
            <p className="text-gray-400">
              The following activities are strictly forbidden and may result in immediate service termination
            </p>
          </div>
          
          <div className="grid sm:grid-cols-3 gap-6">
            <ProhibitedCategory
              title="Content Violations"
              rules={[
                "Illegal or harmful content",
                "Unauthorized copyrighted material",
                "Malicious software or code",
                "Phishing or fraudulent content",
                "Spam or unsolicited communications"
              ]}
            />
            <ProhibitedCategory
              title="Technical Violations"
              rules={[
                "Network abuse or manipulation",
                "Resource overutilization",
                "Security bypass attempts",
                "Unauthorized scanning",
                "System vulnerability exploitation"
              ]}
            />
            <ProhibitedCategory
              title="Legal Compliance"
              rules={[
                "Data privacy violations",
                "Fraud or misrepresentation",
                "Non-compliance with regulations",
                "Unauthorized data collection"
              ]}
            />
          </div>
        </div>

        {/* Service Rules */}
        <div className="mb-12">
          <h2 className="text-2xl font-bold text-white mb-6">Service-Specific Rules</h2>
          <div className="grid sm:grid-cols-3 gap-6">
            <ServiceRules
              title="Game & Minecraft Servers"
              icon={Gamepad}
              rules={[
                "No unauthorized mods or plugins",
                "Fair gameplay only",
                "No exploit abuse",
                "No server-harming modifications"
              ]}
            />
            <ServiceRules
              title="Bot Hosting"
              icon={Bot}
              rules={[
                "No spam bots",
                "No data scraping",
                "Resource limits apply",
                "No unauthorized automation"
              ]}
            />
            <ServiceRules
              title="Web & Domain Hosting"
              icon={Globe}
              rules={[
                "Legal content only",
                "No malware or phishing",
                "Resource quotas apply",
                "No unauthorized content distribution"
              ]}
            />
          </div>
        </div>

        {/* Enforcement and Reporting */}
        <div className="grid md:grid-cols-2 gap-6">
          <Section icon={Gavel} title="Enforcement & Legal Cooperation" index={4}>
            <p>
              We reserve the right to cooperate with legal authorities in investigating violations. 
              This may include sharing user information and activity logs as required by law. 
              Service termination may occur without prior notice for severe violations.
            </p>
          </Section>

          <Section icon={FileWarning} title="Report Violations" index={5}>
            <p className="mb-4">
              If you notice any violations or have concerns, please contact us at:
            </p>
            <motion.a
              href="mailto:abuse@virtue-host.com"
              className="inline-flex items-center gap-2 text-green-400 hover:text-green-300 transition-colors duration-300 group"
              whileHover={{ x: 4 }}
            >
              abuse@virtue-host.com
              <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
            </motion.a>
          </Section>
        </div>
      </section>
    </div>
  );
};

export default AupPage;