import React, { useState, useEffect, memo } from "react";
import { Helmet } from "react-helmet";
import { motion, AnimatePresence } from "framer-motion";
import { useInView } from "react-intersection-observer";
import {
  Search,
  Monitor,
  Smartphone,
  Gamepad,
  CheckCircle,
  AlertCircle,
  ListFilter,
  ArrowRight,
  Gamepad2,
} from "lucide-react";
import { BsController } from "react-icons/bs";

const GAMES = [
  {
    name: "Minecraft",
    img: "https://cdn.virtue-host.com/game-covers/minecraft.webp",
    link: "minecraft",
    startingPrice: "7",
    platforms: ["pc", "mobile", "console"],
    topSeller: true,
    onSale: false,
    newRelease: false,
  },
  {
    name: "CounterStrike",
    img: "https://cdn.virtue-host.com/game-covers/csgo.webp",
    link: "counterstrike",
    startingPrice: "8",
    platforms: ["pc", "console"],
    topSeller: false,
    onSale: false,
    newRelease: false,
  },
  {
    name: "Garry's Mod",
    img: "https://cdn.virtue-host.com/game-covers/gmod.webp",
    link: "garrysmod",
    startingPrice: "8",
    platforms: ["pc"],
    topSeller: false,
    onSale: false,
    newRelease: false,
  },
  {
    name: "RUST",
    img: "https://cdn.virtue-host.com/game-covers/rust.webp",
    link: "rust",
    startingPrice: "24",
    platforms: ["pc", "console"],
    topSeller: false,
    onSale: false,
    newRelease: false,
  },
  {
    name: "Palworld",
    img: "https://cdn.virtue-host.com/game-covers/Palworld.webp",
    link: "palworld",
    startingPrice: "24",
    platforms: ["pc", "console"],
    topSeller: false,
    onSale: false,
    newRelease: false,
  },
  {
    name: "ARK: Survival Evolved",
    img: "https://cdn.virtue-host.com/game-covers/ArkSurvivalEvolved.webp",
    link: "survival-evolved",
    startingPrice: "24",
    platforms: ["pc", "console"],
    topSeller: false,
    onSale: false,
    newRelease: false,
  },
  {
    name: "Satisfactory",
    img: "https://cdn.virtue-host.com/game-covers/Satisfactory.png",
    link: "Satisfactory",
    startingPrice: "24",
    platforms: ["pc"],
    topSeller: false,
    onSale: false,
    newRelease: false,
  },
];

const PlatformIcon = memo(({ platform }) => {
  const baseClasses = "w-5 h-5 text-green-400";

  switch (platform) {
    case "pc":
      return <Monitor className={baseClasses} title="PC" />;
    case "mobile":
      return <Smartphone className={baseClasses} title="Mobile" />;
    case "console":
      return <Gamepad2 className={baseClasses} title="Console" />;
    default:
      return null;
  }
});

const FilterButton = memo(({ label, icon, isActive, onClick }) => (
  <button
    className={`
      flex items-center gap-3 px-4 py-3 rounded-xl
      transition-all duration-300 font-medium
      transform hover:scale-[1.02] active:scale-[0.98]
      ${
        isActive
          ? "bg-virtue-primary-green/75 text-virtue-text-white shadow-lg border border-transparent shadow-virtue-primary-green/25"
          : "bg-virtue-darker-accent text-virtue-text-gray hover:bg-virtue-hover border border-virtue-border hover:border-virtue-primary-green/20"
      }
    `}
    onClick={onClick}
  >
    {icon}
    {label}
  </button>
));

const GameCard = memo(({ game, index }) => {
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  return (
    <div
      ref={ref}
      className={`
        transform transition-all duration-500
        ${inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-5'}
      `}
      style={{ 
        transitionDelay: `${index * 100}ms`
      }}
    >
      <a
        href={game.link}
        className="group block relative rounded-xl bg-virtue-darker-accent/50 border border-virtue-border overflow-hidden
                 transform transition-transform duration-300 hover:scale-[1.02]"
      >
        <div className="relative aspect-[16/9] overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-t from-virtue-background via-transparent to-transparent z-10" />
          <img
            src={game.img}
            alt={game.name}
            className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
          />
          <div className="absolute top-4 right-4 flex gap-2 z-20">
            {game.topSeller && (
              <span className="px-3 py-1.5 rounded-full bg-virtue-primary-green/75 backdrop-blur-sm text-sm font-medium text-black flex items-center gap-1.5">
                <CheckCircle className="w-4 h-4" /> Top Seller
              </span>
            )}
            {game.newRelease && (
              <span className="px-3 py-1.5 rounded-full bg-virtue-primary-green/10 backdrop-blur-sm text-sm font-medium text-virtue-primary-green flex items-center gap-1.5">
                <AlertCircle className="w-4 h-4" /> New
              </span>
            )}
          </div>
          <div className="absolute bottom-0 left-0 right-0 p-6 z-20">
            <h3 className="text-2xl font-bold mb-4 text-virtue-text-white group-hover:text-virtue-primary-green transition-colors duration-300">
              {game.name}
            </h3>
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-3">
                {game.platforms.map((platform) => (
                  <div
                    key={platform}
                    className="p-2 rounded-lg bg-virtue-card/80 backdrop-blur-sm"
                  >
                    <PlatformIcon platform={platform} />
                  </div>
                ))}
              </div>
              <div
                className="flex items-center gap-2 bg-virtue-card/90 px-4 py-2 rounded-lg backdrop-blur-sm
                         transform transition-transform duration-300 hover:scale-105"
              >
                <span className="text-virtue-text-gray text-sm">from</span>
                <span className="text-virtue-primary-green font-bold">
                  ${game.startingPrice}
                </span>
                <span className="text-virtue-text-gray text-sm">/mo</span>
                <ArrowRight className="w-4 h-4 text-virtue-primary-green transform transition-transform duration-300 group-hover:translate-x-1" />
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
});

const SearchBar = memo(({ searchQuery, setSearchQuery }) => (
  <div className="relative">
    <Search className="absolute left-4 top-1/2 -translate-y-1/2 w-5 h-5 text-virtue-primary-green" />
    <input
      type="text"
      className="w-full pl-12 pr-4 py-3 bg-virtue-darker-accent border border-virtue-border rounded-xl
                 text-virtue-text-white placeholder-virtue-text-muted focus:outline-none focus:border-virtue-primary-green/30
                 transition-all duration-300"
      placeholder="Search games..."
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
    />
  </div>
));

const EmptyState = memo(() => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    className="text-center py-24 rounded-xl bg-virtue-darker-accent/50 border border-virtue-border"
  >
    <Search className="w-16 h-16 mx-auto mb-6 text-virtue-primary-green" />
    <h2 className="text-2xl font-bold mb-3 text-virtue-text-white">
      No games found
    </h2>
    <p className="text-virtue-text-gray">
      Try adjusting your search or filters
    </p>
  </motion.div>
));

const GamesPage = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredGames, setFilteredGames] = useState(GAMES);
  const [activePlatform, setActivePlatform] = useState("all");
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  useEffect(() => {
    const results = GAMES.filter((game) => {
      const matchesSearch = game.name
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      return activePlatform === "all"
        ? matchesSearch
        : matchesSearch && game.platforms.includes(activePlatform);
    });
    setFilteredGames(results);
  }, [searchQuery, activePlatform]);

  return (
    <div className="bg-virtue-background  min-h-screen">
      <Helmet>
        <title>Game Servers | Virtue Host</title>
      </Helmet>

      <section className="relative py-24">
        <div className="container mx-auto px-6 relative">
          <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 20 }}
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            className="text-center mb-16"
          >
            <div className="inline-flex items-center gap-2 px-4 py-2 rounded-xl bg-virtue-darker-accent border border-virtue-border mb-6">
              <Gamepad className="w-5 h-5 text-virtue-primary-green" />
              <span className="text-base font-medium text-virtue-text-white">
                Game Servers
              </span>
            </div>

            <h1 className="text-4xl md:text-5xl font-bold mb-6">
              <span className="text-virtue-text-white">Professional </span>
              <span className="text-transparent bg-clip-text bg-virtue-primary-green">
                Game Hosting
              </span>
            </h1>

            <p className="text-xl text-virtue-text-gray max-w-3xl mx-auto leading-relaxed">
              Deploy your game server in seconds with our optimized hosting
              solutions
            </p>
          </motion.div>

          <div className="max-w-4xl mx-auto space-y-8">
            <SearchBar
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />

            <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
              <FilterButton
                label="All Games"
                icon={<ListFilter className="w-5 h-5" />}
                isActive={activePlatform === "all"}
                onClick={() => setActivePlatform("all")}
              />
              <FilterButton
                label="PC Games"
                icon={<Monitor className="w-5 h-5" />}
                isActive={activePlatform === "pc"}
                onClick={() => setActivePlatform("pc")}
              />
              <FilterButton
                label="Mobile Games"
                icon={<Smartphone className="w-5 h-5" />}
                isActive={activePlatform === "mobile"}
                onClick={() => setActivePlatform("mobile")}
              />
              <FilterButton
                label="Console Games"
                icon={<BsController className="w-5 h-5" />}
                isActive={activePlatform === "console"}
                onClick={() => setActivePlatform("console")}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="container  max-w-screen-xl mx-auto px-6 pb-24">
        <AnimatePresence mode="wait">
          {filteredGames.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 ">
              {filteredGames.map((game, index) => (
                <GameCard key={game.name} game={game} index={index} />
              ))}
            </div>
          ) : (
            <EmptyState />
          )}
        </AnimatePresence>
      </section>
    </div>
  );
};

export default memo(GamesPage);
