import React, { useState, memo } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useInView } from "react-intersection-observer";
import {
  Command,
  Download,
  Clock,
  Shield,
  Users,
  LineChart,
  Layers,
  ChevronRight,
} from "lucide-react";

const tabs = [
  {
    id: "create-servers",
    label: "Server Management",
    description:
      "Deploy and manage game servers with our intuitive one-click system. Scale your infrastructure instantly with automated deployment.",
    icon: <Layers className="w-5 h-5" />,
    imageUrl: "https://cdn.virtue-host.com/company/panel.webp",
  },
  {
    id: "pull-files",
    label: "File Management",
    description:
      "Streamlined mod and plugin installation with direct URL integration. Download and manage server files effortlessly.",
    icon: <Download className="w-5 h-5" />,
    imageUrl: "https://cdn.virtue-host.com/panel/pullfiles.png",
  },
  {
    id: "automated-schedules",
    label: "Task Automation",
    description:
      "Set up sophisticated automation for backups, restarts, and maintenance tasks. Keep your servers running optimally 24/7.",
    icon: <Clock className="w-5 h-5" />,
    imageUrl: "https://cdn.virtue-host.com/panel/schedules.png",
  },
  {
    id: "backup-restore",
    label: "Backup System",
    description:
      "Enterprise-grade backup solution with automated scheduling and one-click restore functionality.",
    icon: <Shield className="w-5 h-5" />,
    imageUrl: "https://cdn.virtue-host.com/panel/backups.png",
  },
  {
    id: "user-permissions",
    label: "Access Control",
    description:
      "Granular permission system for team management. Control access levels and monitor user activities.",
    icon: <Users className="w-5 h-5" />,
    imageUrl: "https://cdn.virtue-host.com/panel/permissions.png",
  },
  {
    id: "monitor-activity",
    label: "Performance Metrics",
    description:
      "Real-time monitoring and analytics for your game servers. Track performance and player activity.",
    icon: <LineChart className="w-5 h-5" />,
    imageUrl: "https://cdn.virtue-host.com/panel/monitor.png",
  },
];

const TabButton = memo(({ icon, label, isActive, onClick }) => (
  <button
    className={`flex items-center px-3 py-2 rounded-lg w-full group 
      transition-all duration-300 ease-in-out transform hover:scale-[1.01] active:scale-[0.98]
      ${
        isActive
          ? "bg-virtue-card border border-virtue-primary-green/20 shadow-sm"
          : "hover:bg-virtue-darker-accent/30 border border-transparent"
      }`}
    onClick={onClick}
  >
    <div
      className={`w-8 h-8 mr-2 flex items-center justify-center rounded-md
      transition-colors duration-300
      ${
        isActive
          ? "bg-virtue-primary-green/10 text-virtue-primary-green"
          : "bg-virtue-darker-accent/50 text-virtue-text-muted group-hover:text-virtue-primary-green"
      }`}
    >
      {React.cloneElement(icon, { className: "w-4 h-4" })}
    </div>
    <div className="flex-1 text-left">
      <p
        className={`text-sm font-medium transition-colors duration-300
        ${
          isActive
            ? "text-virtue-text-white"
            : "text-virtue-text-gray group-hover:text-virtue-text-white"
        }`}
      >
        {label}
      </p>
    </div>
    <ChevronRight
      className={`w-3 h-3 transition-all duration-300
      ${
        isActive
          ? "opacity-100 text-virtue-primary-green translate-x-0"
          : "opacity-0 -translate-x-2 group-hover:opacity-100 group-hover:translate-x-0"
      }`}
    />
  </button>
));

const TabContent = memo(({ tab, isActive }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }}
    transition={{ duration: 0.3 }}
    className="relative"
  >
    <div className="space-y-8">
      <div className="space-y-4">
        <div className="flex items-center gap-3 mb-6">
          <div className="w-12 h-12 rounded-xl bg-virtue-primary-green/10 flex items-center justify-center text-virtue-primary-green">
            {tab.icon}
          </div>
          <h3 className="text-2xl font-bold text-virtue-text-white">
            {tab.label}
          </h3>
        </div>
        <p className="text-virtue-text-gray text-lg leading-relaxed">
          {tab.description}
        </p>
      </div>

      <div
        className="relative group rounded-xl overflow-hidden border border-virtue-border 
                   hover:border-virtue-primary-green/20 transition-all duration-300 bg-virtue-darker-accent/50
                   aspect-[16/9] w-full transform hover:scale-[1.01]"
      >
        <div
          className="absolute inset-0 bg-gradient-to-b from-transparent via-virtue-darker-accent/5 to-virtue-darker-accent/20 
                       opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none"
        />

        <div className="absolute inset-0">
          <img
            className="w-full h-full object-cover object-center"
            src={tab.imageUrl}
            alt={`${tab.label} interface`}
            loading="lazy"
          />
        </div>
      </div>
    </div>
  </motion.div>
));

const Panel = () => {
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  const activeTabContent = tabs.find((tab) => tab.id === activeTab);

  return (
    <section
      ref={ref}
      className="bg-virtue-background py-24 relative overflow-hidden"
    >
      <div className="container mx-auto px-6 max-w-screen-xl relative">
        <div
          className={`text-center mb-16 transition-all duration-500 transform
          ${inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-5"}`}
        >
          <div className="inline-flex items-center gap-2 px-4 py-2 rounded-xl bg-virtue-darker-accent border border-virtue-border mb-6">
            <Command className="w-5 h-5 text-virtue-primary-green" />
            <span className="text-base font-medium text-virtue-text-white">
              Control Panel Features
            </span>
          </div>

          <h2 className="text-4xl md:text-5xl font-bold mb-6">
            <span className="text-virtue-text-white">Advanced </span>
            <span className="text-transparent bg-clip-text bg-virtue-primary-green">
              Server Management
            </span>
          </h2>

          <p className="text-xl text-virtue-text-gray max-w-3xl mx-auto leading-relaxed">
            Take control of your game servers with our powerful Pterodactyl
            panel. Built for performance and ease of use, with enterprise-grade
            features.
          </p>
        </div>

        <div className="flex flex-col lg:flex-row gap-12">
          <div
            className={`lg:w-1/3 transition-all duration-500 transform
            ${
              inView ? "opacity-100 translate-x-0" : "opacity-0 -translate-x-12"
            }`}
          >
            <div className="flex flex-col space-y-3 sticky top-24">
              {tabs.map((tab) => (
                <TabButton
                  key={tab.id}
                  icon={tab.icon}
                  label={tab.label}
                  isActive={activeTab === tab.id}
                  onClick={() => setActiveTab(tab.id)}
                />
              ))}
            </div>
          </div>

          <div
            className={`lg:w-2/3 transition-all duration-500 transform
            ${
              inView ? "opacity-100 translate-x-0" : "opacity-0 translate-x-12"
            }`}
          >
            <AnimatePresence mode="wait">
              <TabContent
                key={activeTab}
                tab={activeTabContent}
                isActive={true}
              />
            </AnimatePresence>
          </div>
        </div>
      </div>
    </section>
  );
};

export default memo(Panel);
